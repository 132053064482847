<template>
  <div class="player">
    <div v-if="!hide_sms_block" class="client-sms-collapse-wrapper">
      <ClientSmsCollapse />
    </div>
    <div class="filter-wrapper">
      <FilterForm
        v-if="playerModuleInfo && playerModuleInfo.filter && forFilterFormRefresh"
        :filter-list="playerModuleInfo.filter"
        :own-submit="true"
        :is-loading="isLoading"
        :old-form-values="playerModuleInfo.searchParams"
        :has-reset-button="true"
        @reset-search-fields="resetSearchFields"
        @filter-form-search="onFilterFormSearch"
      />
    </div>
    <div style="margin-right: 4%">
      <DownLoadFile v-if="playerModuleInfo && playerModuleInfo.download_token"
                    :search-params="{
                      ...playerModuleInfo.searchParams,
                      perPage: 10000000,
                      token: playerModuleInfo.download_token,
                    }"
      />
    </div>
    <div class="client-table-wrapper">
      <AntdTable
        :loading="isLoading"
        :columns="columns"
        :order-params="orderParams"
        :total="playerModuleInfo && playerModuleInfo.player_module.total"
        :rows="rows"
        :page-count="pageCount"
        :page-value="page"
        :per-page="perPage"
        :pagination="true"
        table-title="Client List"
        @search-by-order="searchByOrder"
        @per-page-select-change="perPageSelectChange"
        @on-page-change="pageChange"
        @update-table="getPlayerInfo"
      />
    </div>
    <EditFormPage
      :visible="isEditFormModalOpen"
      type="edit"
      update-dispatch-action="player/updatePlayerModuleById"
      unmount-action="player/deleteEditFormJson"
      get-edit-form-dispatch-action="player/editPlayerModuleById"
      state-name="player"
      request-path="/player/client"
      :id-of-edit-modal="isEditFormModalOpen"
      @toggle-modal="toggleEditFormModal"
    />
    <DeleteFormModal
      v-if="deleteFormModalInfo"
      delete-vuex-action="player/softDeleteById"
      :info="deleteFormModalInfo"
      form-route="soft-delete-form"
      @update-table-info="updateAfterDelete"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex';
// import Table from '../../Table/Table';
import AntdTable from '../../Table/AntdTable';
import ClientSmsCollapse from './ClientSmsCollapse';
import EditFormPage from '../../../views/EditFormPage';
import DeleteFormModal from '../Modals/DeleteFormModal';
// import CustomFilterList from './partial/CustomFilterList/CustomFilterList';
// import RangeFilter from './partial/RangeFilter/RangeFilter';
import FilterForm from '../CustomFilterList/FilterForm';
import DownLoadFile from '../DownLoadFile';

export default {
  components: {
    AntdTable, FilterForm, ClientSmsCollapse, DownLoadFile, EditFormPage, DeleteFormModal,
  },
  data() {
    return {
      isLoading: false,
      columns: [],
      rows: [],
      pageCount: 10,
      page: 1,
      perPage: 10,
      orderParams: {},
      searchParams: {},
      selectedSearchDate: {},
      rangeFiltersValues: {},
      smsTextArea: '',
      forFilterFormRefresh: true,
      lastRoute: null,
    };
  },
  computed: {
    ...mapState({
      playerModuleInfo(state) {
        return state.player.playerInfo[this.$route.params.module];
      },
      deleteFormModalInfo(state) {
        return state.modals.isDeleteFormModalOpen[this.$route.params.module];
      },
      hide_sms_block(state) {
        return state.player.playerInfo[this.$route.params.module]
          && state.player.playerInfo[this.$route.params.module].hide_sms_block;
      },
      isEditFormModalOpen(state) {
        return state.modals.isEditFormModalOpen;
      },
    }),
  },
  async mounted() {
    if (!this.playerModuleInfo) {
      await this.getPlayerInfo();
    } else {
      this.setSettings();
    }
    this.lastRoute = _.cloneDeep(this.$route.params);
  },
  async destroyed() {
    // eslint-disable-next-line max-len
    // await this.$store.dispatch('player/destroyClientListFromVuex', { key: this.$route.params.module });
  },
  methods: {
    async updateAfterDelete() {
      await this.getPlayerInfo();
      this.setSettings();
    },
    onRangeFilterInputChange(data) {
      this.rangeFiltersValues = {
        ...this.rangeFiltersValues,
        ...data,
      };
    },
    async toggleEditFormModal() {
      await this.$store.dispatch('modals/toggleEditFormModal');
      const query = _.clone(this.$route.query);
      delete query.modalId;
      await this.$router.replace({ query });
      await this.getPlayerInfo();
      this.setSettings();
    },
    setRangeFiltersValues() {
      this.rangeFiltersValues = (this.playerModuleInfo && this.playerModuleInfo.rangeFiltersValues);
    },
    setSearchParams() {
      this.searchParams = (this.playerModuleInfo && this.playerModuleInfo.searchParams);
    },
    async resetSearchFields() {
      this.forFilterFormRefresh = false;
      await this.onFilterFormSearch({});
      this.forFilterFormRefresh = true;
    },
    setSettings() {
      this.columns = this.playerModuleInfo.columns;
      this.rows = this.playerModuleInfo.player_module.data;
      this.perPage = this.playerModuleInfo.player_module.per_page;
      // eslint-disable-next-line max-len
      this.pageCount = Math.ceil(this.playerModuleInfo.player_module.total / this.playerModuleInfo.player_module.per_page);
      this.page = this.playerModuleInfo.player_module.current_page;
      this.setRangeFiltersValues();
      this.setSearchParams();
    },
    async searchByInputValue() {
      await this.getPlayerInfo();
      this.setSettings();
    },
    async searchByOrder(e) {
      this.orderParams = e;
      await this.getPlayerInfo();
    },
    async perPageSelectChange() {
      await this.getPlayerInfo();
    },
    async pageChange() {
      await this.getPlayerInfo();
    },
    async handleDatePickerUpdate() {
      // console.log('handleDatePickerUpdate');
    },
    handleCustomFilterChange(e) {
      this.searchParams = e.searchInputValues && { ...e.searchInputValues };
    },
    async onFilterFormSearch(filterFormData) {
      this.searchParams = { ...filterFormData };
      if (this.$route.query.page !== '1') {
        await this.$router.push({ query: { page: '1' } });
      }
      await this.getPlayerInfo();
      this.setSettings();
    },
    async getPlayerInfo() {
      if (!this.isLoading) {
        this.isLoading = true;
        const params = {
          page: this.$route.query.page || 1,
          path: this.$route.path,
          name: this.$route.params.module,
          perPage: localStorage.getItem('tablePerPage') || this.$route.query.perPage || 10,
          searchParams: this.searchParams,
          orderParams: this.orderParams,
          rangeFiltersValues: this.rangeFiltersValues,
          // selectedSearchDate: this.selectedSearchDate,
          // ...this.advancedSearchValue,
        };
        await this.$store.dispatch('player/getPlayerInfo', params);
        this.setSettings();
        this.isLoading = false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.player{
  padding: 24px 20px;
  .client-sms-collapse-wrapper{
    margin-bottom: 16px;
  }
  .filter-wrapper{
  }
  .client-table-wrapper{
  }
}
</style>
