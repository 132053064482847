<template>
  <a-modal :visible="Boolean(visible)"
           width="1000px"
           :footer="null"
           @cancel="() => $emit('toggle-modal')"
  >
    <template slot="title">
      <a-steps
        v-model="currentStep"
        style="margin-top: 35px"
      >
        <a-step
          v-for="(stepNumber, stepIndex) in stepDivision.length"
          :key="stepNumber"
          :status="stepValidator(stepIndex, stepValidation)"
          :title="`Step ${stepNumber}`"
        />
      </a-steps>
    </template>
    <div class="steps-content">
      <div class="create-form-wrapper">
        <NewStepForm1
          v-if="visible"
          :form-data="formCreateJson"
          :old-form-values="defaultFormValue || {}"
          :current-step="stepDivision[currentStep]"
          :step-division="stepDivision"
          :validation-rules="validationRules"
          :is-next-step-active="currentStep < stepDivision.length - 1"
          :is-prev-step-active="currentStep > 0"
          :is-form-data-loading="isLoading"
          :is-front-valid-active="isFrontValidActive"
          :other-form-items="searchParams"
          :submit-path="!action || action==='create' ? `${$route.path}/store`
            : `${$route.path}/${action}/action`"
          @next="next"
          @prev="prev"
          @show-step-validation="showStepValidation"
          @change-form="changeForm"
          @toggle-modal="() => $emit('toggle-modal')"
        />
      </div>
    </div>
    <div class="steps-action">
      <a-button v-if="currentStep < stepDivision.length - 1" type="primary" @click="next">
        Next
      </a-button>
      <a-button v-if="currentStep > 0" style="margin-left: 8px" @click="prev">
        Back
      </a-button>
    </div>
  </a-modal>
</template>

<script>
import { mapState } from 'vuex';
import lodash from 'lodash';
// import FormPage1 from '../components/partial/FormPage/FormPage1';
import NewStepForm1 from '../components/partial/FormPage/NewStepForm1';
// import socketError from '../utils/socketError';

export default {
  components: { NewStepForm1 },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    reqAddress: {
      type: String,
      default: '',
    },
    action: {
      type: String,
      default: '',
    },
    searchParams: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      isLoading: false,
      errors: {},
      currentStep: 0,
      perStepLength: 5,
      stepValidation: [],
      lodash,
    };
  },
  computed: {
    ...mapState({
      validationRules() {
        return {
          name: {
            required: { message: 'sdasa' },
          },
          am_seo_image: {
            required: {
              errorRef: 'seo_image',
            },
          },
          group_order: {
            min: {
              targetValue: 5,
              message: 'sdasa',
            },
          },
          translation: {
            am: {
              title: {
                required: {},
              },
            },
            ru: {
              title: {},
            },
          },
        };
        // eslint-disable-next-line max-len
        // return state.gameModule.formCreateJson.formData && state.gameModule.formCreateJson.formData.validationRules;
      },
      formCreateJson(state) {
        // eslint-disable-next-line max-len
        // const asd = { ...state.gameModule.formCreateJson.formData && state.gameModule.formCreateJson.formData.fields };
        // const dsa = {};
        // _.forIn(asd, (fieldOption, fieldName) => {
        //   console.log('fieldName', fieldName);
        //   if (fieldOption.translatable) {
        //     dsa[fieldName] = {
        //       ...asd[fieldName],
        //       validation: {
        //         rules: {
        //           am: {
        //             required: true,
        //           },
        //           ru: {
        //             required: true,
        //           },
        //           en: {
        //             required: true,
        //           },
        //         },
        //       },
        //     };
        //   } else if (fieldOption.translatable && fieldName === 'group_order') {
        //     dsa[fieldName] = {
        //       ...asd[fieldName],
        //       validation: {
        //         rules: {
        //           max: {
        //             value: 5,
        //             message: 'fdffff',
        //           },
        //         },
        //       },
        //     };
        //   } else {
        //     dsa[fieldName] = {
        //       ...asd[fieldName],
        //       validation: {
        //         rules: {
        //           required: true,
        //         },
        //       },
        //     };
        //   }
        // });
        // console.log(dsa);
        // return dsa;
        // eslint-disable-next-line max-len
        return state.gameModule.formCreateJson.formData && state.gameModule.formCreateJson.formData.fields;
      },
      isFrontValidActive(state) {
        // eslint-disable-next-line max-len
        return state.gameModule.formCreateJson.formData && state.gameModule.formCreateJson.formData.isFrontValidActive;
      },
      serverStepDivisionRules(state) {
        // eslint-disable-next-line max-len
        return state.gameModule.formCreateJson.formData && state.gameModule.formCreateJson.formData.serverStepDivisionRules;
      },
      defaultFormValue(state) {
        // eslint-disable-next-line max-len
        return state.gameModule.formCreateJson.formData && state.gameModule.formCreateJson.formData.result;
      },
    }),
    stepDivision() {
      if (this.serverStepDivisionRules) {
        return this.serverStepDivisionRules;
      }
      return this.formCreateJson
        ? _.chunk(Object.keys(this.formCreateJson), this.perStepLength) : [];
    },
  },
  watch: {
    visible(e) {
      if (e) {
        this.getFormJson();
      }
    },
  },
  mounted() {
    if (this.visible) {
      this.getFormJson();
    }
  },
  methods: {
    changeForm(data) {
      this.getFormJson(data);
    },
    stepValidator(stepIndex, stepValidation) {
      return _.intersection(stepValidation, this.stepDivision[stepIndex]).length
        ? 'error' : '';
    },
    showStepValidation(errors) {
      this.stepValidation = Object.keys(errors);
    },
    next() {
      this.currentStep++;
    },
    prev() {
      this.currentStep--;
    },
    async getFormJson(params) {
      this.isLoading = true;
      await this.$store.dispatch('gameModule/createFormPage',
        { path: `${this.$route.path}/${this.action || 'create'}`, params });
      this.isLoading = false;
    },
  },
};
</script>

<style scoped lang="scss">
.create-form-wrapper{
  width: 100%;
}
.create-form-page{
  width: 100%;
  background: #33313136;
  position: absolute;
  height: 100%;
  overflow-y: scroll;
  top: 0;
  left: 0;
  z-index: 654;
  .component-wrapper{
    width: 95%;
    margin: 15vh auto 0;
    background: white;
    position: relative;
    border-radius: 10px;
    .close{
      position: absolute;
      top: -10px;
      left: -10px;
      cursor: pointer;
    }
  }
}
</style>
