import axios from 'axios';
import Vue from 'vue';
// import moment from 'moment';
import socketError from '../utils/socketError';

export default {
  namespaced: true,

  state: {
    loading: false,
    reportInfo: {},
    reportList: [],
    collapseReport: {},
    sportReportInfo: {
      columns: {},
      reports: [],
      reports_name: '',
    },
  },
  mutations: {
    setSportReportInfo(state, data) {
      const columns = {};
      data.columns.forEach((col) => {
        columns[col.field] = col;
      });
      state.sportReportInfo.columns = columns;
      state.sportReportInfo.reports = data.reports;
      state.sportReportInfo.reports_name = data.reports_name;
    },
    setCollapseReport(state, collapseReport) {
      Vue.set(state, 'collapseReport', collapseReport);
    },
    setReportInfo(state, { key, value }) {
      const newVal = { ...value };
      function setRows() {
        if (value.reports_name === 'byPayment') {
          newVal.reports.data = value.reports.data.map((elem) => {
            const oneRow = { ...elem };
            oneRow.balance = oneRow.deposit_amount - oneRow.withdraw_amount;
            return oneRow;
          });
        }
      }
      function addCell(columns) {
        columns.push({
          label: 'Balance',
          field: 'balance',
          // eslint-disable-next-line camelcase
          representedAs: ({ deposit_amount, withdraw_amount }) => `${deposit_amount - withdraw_amount}`,
        });
      }
      function setColumns() {
        const columns = [...value.columns];
        if (value.reports_name === 'byPayment') {
          addCell(columns);
        }
        newVal.columns = columns.sort((a, b) => {
          if (a.label.toLowerCase().indexOf('id') !== -1 || a.label.toLowerCase().indexOf('username') !== -1) {
            return -1;
          }
          if (b.label.toLowerCase().indexOf('id') !== -1 || b.label.toLowerCase().indexOf('username') !== -1) {
            return 1;
          }
          return 0;
        });
      }
      if (value.reports_name !== 'byGame' && value.reports_name !== 'jackpot') {
        setColumns();
      }
      // setColumns();
      setRows();
      // console.log('newVal', newVal);
      Vue.set(state.reportInfo, key, newVal);
    },
  },
  actions: {
    async getCollapseReport({ dispatch, commit }, { path, filterFormParams }) {
      try {
        const params = { ...filterFormParams } || {};
        if (filterFormParams && filterFormParams.date_range) {
          params.endDate = filterFormParams.date_range.endDate;
          params.startDate = filterFormParams.date_range.startDate;
          delete params.date_range;
        }
        const res = await axios.get(`${path}`, { params });
        const value = { ...res.data };
        if (filterFormParams) {
          value.searchParams = filterFormParams;
          value.searchParamsForDownloadFile = { ...params };
        }
        commit('setCollapseReport', { ...value });
        return res.data;
      } catch (e) {
        socketError({
          dispatch,
          commit,
        }, e);
        return false;
      }
    },
    async getSportReportInfo({ dispatch, commit },
      {
        path,
      }) {
      try {
        const res = await axios.get(`${path}`);
        commit('setSportReportInfo', res.data);
        return res.data;
      } catch (e) {
        socketError({
          dispatch,
          commit,
        }, e);
        return false;
      }
    },
    async getReportInfo({ dispatch, commit },
      {
        page, path, name, orderParams,
        perPage, filterFormData, currency,
      }) {
      // eslint-disable-next-line max-len
      // const endDate = (selectedSearchDate.endDate && moment(selectedSearchDate.endDate).unix()) || moment().unix();
      // eslint-disable-next-line max-len
      // const startDate = (selectedSearchDate.startDate && moment(selectedSearchDate.startDate).unix()) || '';
      // console.log(selectedSearchDate);
      // commit('setReportStates', { reportName: key, isLoading: true, error: false });
      try {
        const params = {
          notFormPage: true,
          orderBy: orderParams.orderBy || 'ASC',
          column: orderParams.column || '',
          perPage: perPage || 10,
          page: page || 1,
          currency: currency || '',
          ...filterFormData,
        };
        const res = await axios.get(`${path}`, {
          params,
        });
        const data = _.clone(res.data);
        data.ordering = orderParams || {};
        data.currency = currency;
        data.filterFormData = filterFormData;
        commit('setReportInfo', { key: name, value: data });
        return data;
      } catch (e) {
        // commit('setReportStates', { reportName: key, isLoading: false, error: true });
        socketError({
          dispatch,
          commit,
        }, e);
        return false;
      }
    },
  },
  getters: {
    loading(state) {
      return state.loading;
    },
    reportInfo(state) {
      return state.reportInfo;
    },
    sportReportInfo(state) {
      return state.loading;
    },
    reportList(state) {
      return state.reportList;
    },
    collapseReport(state) {
      return state.collapseReport;
    },
  },
};
