<template>
  <div style="margin-left: 10px">
    <a-select v-model="selectedValue" style="width: 200px" @change="changeHandler">
      <a-select-option
        v-for="option in sitePartnerList"
        :key="option.site_id"
        :value="option.site_id"
      >
        {{ option.name }}
      </a-select-option>
    </a-select>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  data() {
    return {
      selectedValue: '',
    };
  },
  computed: {
    ...mapState({
      sitePartnerList(state) {
        return state.info.userInfo.sites || [];
      },
      defSiteId(state) {
        return state.info.userInfo.site_id;
      },
    }),
  },
  created() {
    if (localStorage.getItem('selectedPartner')) {
      this.selectedValue = Number(localStorage.getItem('selectedPartner'));
    } else {
      localStorage.setItem('selectedPartner', this.defSiteId);
      this.selectedValue = this.defSiteId;
    }
  },
  mounted() {
    this.setSiteIdToInterceptors();
  },
  methods: {
    changeHandler(e) {
      localStorage.setItem('selectedPartner', e);
      this.$nextTick(() => {
        window.location.reload();
      });
    },
    setSiteIdToInterceptors() {
      axios.interceptors.request.use((config) => {
        // eslint-disable-next-line no-param-reassign
        config.params = { ...config.params, selectedSiteId: this.selectedValue };
        return config;
      });
    },
  },
};
</script>
