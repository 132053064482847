<template>
  <div :key="$route.path" class="game-module-wrapper">
    <div class="page-header-wrapper">
      <div v-if="isTableTranslatable" class="lang-comp-wrapper">
        <span style="">Language</span>
        <LanguageSwitcher />
      </div>
      <SearchBar
        v-if="searchFields"
        :columns="searchFields"
        :search-bar="searchBar"
        :is-loading="isLoading"
        @search-by-filter-value="searchByFilterValue"
      />
    </div>
    <div class="game-module">
      <div>
        <div v-if="gameModuleData && gameModuleData.range_filter" class="range-filter-wrapper">
          <RangeFilter
            v-for="(filter, index) in gameModuleData.range_filter"
            :key="index"
            :is-loading="isLoading"
            :field="filter.field"
            :label="filter.label"
            :to-val="rangeFiltersValues[filter.field + '_to'] || ''"
            :from-val="rangeFiltersValues[filter.field + '_from'] || ''"
            @on-range-filter-input-change="onRangeFilterInputChange"
          />
          <a-button type="primary" icon="search" @click="searchByFilters">
            Search
          </a-button>
        </div>
        <div>
          <FilterForm
            v-if="gameModuleData && gameModuleData.filter"
            :key="JSON.stringify(oldFilterValues)"
            :filter-list="gameModuleData.filter"
            :own-submit="true"
            :is-loading="isLoading"
            :old-form-values="oldFilterValues"
            :has-reset-button="true"
            @reset-search-fields="resetSearchFields"
            @filter-form-search="onFilterFormSearch"
          />
        </div>
        <div v-if="['cash-logs', 'cashback-checker'].includes(
          $route.params.moduleName)
          && gameModuleData && gameModuleData.total"
        >
          <AntdTable
            :loading="isLoading"
            :columns="gameModuleData.total.columns"
            :rows="totalRowInfo"
          />
        </div>
        <div v-if="['client-balance-snapshots', 'bet-shops'].includes(
               $route.params.moduleName)
               && gameModuleData && gameModuleData.total"
             style="margin-bottom: 20px"
        >
          <AntdTable
            :loading="isLoading"
            :columns="gameModuleData.total.columns"
            :rows="totalRowInfoClientBalance"
          />
        </div>
        <div v-if="['withdraw-requests'].includes(
          $route.params.moduleName) && moduleTotalBlock"
        >
          <AntdTable
            :loading="isLoading"
            :columns="moduleTotalBlock.columns"
            :rows="moduleTotalBlock.results"
          />
        </div>
        <div>
          <AntdTable
            :columns="columns"
            :rows="rows"
            :no-refresh="actionCodes.includes('reindex-all')"
            :order-params="ordering"
            :bulk-delete="bulkDelete"
            :table-title="gameModule && gameModule.tableTitle"
            :loading="isLoading"
            :search-params="{
              ...filterFormData,
              ...filterValue
            }"
            :page-count="Math.ceil(gameModule && gameModule.total / gameModule.per_page)"
            :total="gameModule && gameModule.total"
            :page-value="gameModule && gameModule.current_page"
            :per-page="gameModule && gameModule.per_page"
            :pagination="gameModule && gameModule.per_page && rows.length >= gameModule.per_page
              || (gameModule && gameModule.last_page_url)"
            :translatable="gameModuleData && gameModuleData.translatable"
            :footer-info="footerInfo"
            :table-footer="tableFooterArray"
            :expanded-row-query-data="expandedRowQueryData"
            @on-page-change="pageChange"
            @per-page-select-change="perPageSelectChange"
            @search-by-order="searchByOrder"
            @update-table="getGameModule"
          >
            <div v-if="actions && actions[0]" class="button-wrapper">
              <span v-for="(action, index) in actions" :key="index">
                <a-button v-if="action.action === 'create'"
                          type="primary"
                          @click="toggleFormModal('create')"
                >
                  + {{ action.label }}
                </a-button>
                <a-button v-else-if="action.action === 'Create Wheel'"
                          type="primary"
                          @click="toggleFormModal('create')"
                >
                  <router-link
                    to="/admin/wheel-control"
                  >
                    + {{ action.label }}
                  </router-link>
                </a-button>
                <a-button v-else-if="action.action === 'reindex-all'"
                          @click="reindexAll"
                >
                  {{ action.label }}
                </a-button>
                <a-button v-else-if="action.action === 'update_filtered'"
                          type="dashed"
                          @click="toggleFormModal('update_filtered')"
                >
                  {{ action.label }}
                </a-button>
                <a-input-search
                  v-else-if="action.type === 'withInput'"
                  :placeholder="action.inputPlaceholder || ''"
                  style="max-width: 400px"
                  :type="action.inputType || 'text'"
                  :default-value="action.inputDefaultValue || ''"
                  @search="(inputValue) => tableWithInputActionHandler(inputValue, action.action)"
                >
                  <a-button slot="enterButton" type="primary">
                    {{ action.label }}
                  </a-button>
                </a-input-search>
                <a-button v-else
                          @click="tableActionHandler(action.action)"
                >
                  {{ action.label }}
                </a-button>
              </span>
            </div>
            <PrintFile
              v-if="gameModuleData && gameModuleData.printable"
              :search-params="{
                orderBy: ordering && ordering.orderBy || 'ASC',
                column: ordering && ordering.column || '',
                perPage: 5000,
                ...oldFilterValues,
                ...filterValue,
                currency: selectedCurrency || '',
              }"
            />
            <div style="margin: 0 10px">
              <DownLoadFile v-if="gameModuleData && gameModuleData.download_token"
                            :total-count="gameModule && gameModule.total"
                            :search-params="{
                              orderBy: ordering && ordering.orderBy || 'ASC',
                              column: ordering && ordering.column || '',
                              reportName: $route.path,
                              perPage: 10000000,
                              token: gameModuleData && gameModuleData.download_token,
                              ...oldFilterValues,
                              ...filterValue,
                              currency: selectedCurrency || '',
                            }"
              />
              <!--    <DownLoadFileBackground-->
              <!--      v-if="gameModuleData && gameModuleData.download_token"-->
              <!--      :search-params="{-->
              <!--        ...filterFormData,-->
              <!--        ...filterValue,-->
              <!--        token: gameModuleData && gameModuleData.download_token,-->
              <!--        currency: selectedCurrency || '',-->
              <!--      }"-->
              <!--    />-->
            </div>
            <div v-if="currencyOptions">
              <a-select
                v-model="selectedCurrency"
                style="width: 120px; margin-right: 14px"
                placeholder="Currency"
                @change="getGameModule"
              >
                <a-select-option
                  v-for="option in currencyOptions"
                  :key="option.code"
                  :disabled="option.disabled"
                >
                  {{ option.label }}
                </a-select-option>
              </a-select>
            </div>
          </AntdTable>
        </div>
        <div v-if="$route.params.moduleName === 'withdraw-requests'
          && gameModuleData && gameModuleData['total_result']
          && gameModuleData['total_result_columns']"
        >
          <AntdTable
            :loading="isLoading"
            :columns="gameModuleData['total_result_columns']"
            :rows="[gameModuleData['total_result']]"
          />
        </div>
      </div>
      <DeleteModal
        v-if="deleteModalInfo"
        delete-vuex-action="gameModule/deleteSelectedUser"
        :info="deleteModalInfo"
        @update-table-info="updateAfterAction"
      />
      <CreateFormPage
        v-if="Boolean(isFormModalOpen)"
        :visible="Boolean(isFormModalOpen)"
        :action="isFormModalOpen || ''"
        :search-params="{
          ...filterFormData,
          ...filterValue
        }"
        @toggle-modal="toggleFormModal"
      />
      <IframeModal
        :visible="Boolean(isIframeModalOpen)"
        :req-url="isIframeModalOpen"
        @toggle-modal="toggleIframeModal"
      />
      <EditFormPage
        v-if="Boolean(isEditFormModalOpen)"
        :visible="isEditFormModalOpen"
        type="edit"
        update-dispatch-action="gameModule/updateModuleById"
        unmount-action="gameModule/deleteEditFormJson"
        get-edit-form-dispatch-action="gameModule/editModuleById"
        state-name="gameModule"
        request-path="/admin"
        :id-of-edit-modal="isEditFormModalOpen"
        @toggle-modal="toggleEditFormModal"
      />
      <DeleteFormModal
        v-if="deleteFormModalInfo"
        delete-vuex-action="gameModule/softDeleteById"
        :info="deleteFormModalInfo"
        form-route="soft-delete-form"
        @update-table-info="updateAfterAction"
      />
      <RejectModal
        v-if="isRejectModalOpen"
        :modal-form-content="isRejectModalOpen"
        @update-table-info="updateAfterAction"
      />
      <ViewImageModal
        v-if="isImageModalOpen"
        :info="isImageModalOpen"
      />
      <ApproveModal
        v-if="isApproveModalOpen"
        :id="isApproveModalOpen"
      />
      <ChangePassModal
        v-if="isChangePassModalOpen"
        :id="isChangePassModalOpen"
      />
      <DynamicFormModal
        v-if="dynamicFormModalInfo"
        :modal-form-info="dynamicFormModalInfo"
        @update-table-info="getGameModule"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import DeleteModal from '../components/partial/Modals/DeleteModal';
import SearchBar from '../components/partial/SearchBar/SearchBar';
import CreateFormPage from './CreateFormPage';
import EditFormPage from './EditFormPage';
import LanguageSwitcher from '../components/partial/LanguageSwitcher';
import socketError from '../utils/socketError';
import RejectModal from '../components/partial/Modals/RejectModal';
import ViewImageModal from '../components/partial/Modals/ViewImageModal';
import RangeFilter from '../components/partial/RangeFilter/RangeFilter';
import FilterForm from '../components/partial/CustomFilterList/FilterForm';
import AntdTable from '../components/Table/AntdTable';
import IframeModal from '../components/partial/Modals/IframeModal';
import ApproveModal from '../components/partial/Modals/ApproveModal';
import DeleteFormModal from '../components/partial/Modals/DeleteFormModal';
import ChangePassModal from '../components/partial/Modals/ChangePassModal';
import DownLoadFile from '../components/partial/DownLoadFile';
import PrintFile from '../components/partial/PrintFile';
import DynamicFormModal from '../components/partial/Modals/DynamicFormModal';

export default {
  components: {
    DynamicFormModal,
    DeleteModal,
    SearchBar,
    CreateFormPage,
    EditFormPage,
    LanguageSwitcher,
    RejectModal,
    ViewImageModal,
    RangeFilter,
    FilterForm,
    AntdTable,
    IframeModal,
    ApproveModal,
    DeleteFormModal,
    ChangePassModal,
    DownLoadFile,
    PrintFile,
  },
  data() {
    return {
      isLoading: false,
      page: 1,
      filterValue: {},
      orderParams: {},
      rangeFiltersValues: {},
      filterFormData: {},
      selectedCurrency: undefined,
    };
  },
  computed: {
    ...mapState({
      deleteFormModalInfo(state) {
        return state.modals.isDeleteFormModalOpen[this.$route.params.moduleName];
      },
      dynamicFormModalInfo(state) {
        return state.modals.dynamicFormModalInfo;
      },
      isChangePassModalOpen(state) {
        return state.modals.isChangePassModalOpen;
      },
      isApproveModalOpen(state) {
        return state.modals.isApproveModalOpen;
      },
      isIframeModalOpen(state) {
        return state.modals.isIframeModalOpen;
      },
      isImageModalOpen(state) {
        return state.modals.isImageModalOpen;
      },
      betShops(state) {
        return state.gameModule.betShops;
      },
      isRejectModalOpen(state) {
        return state.modals.isRejectModalOpen;
      },
      isEditFormModalOpen(state) {
        return state.modals.isEditFormModalOpen;
      },
      isFormModalOpen(state) {
        return state.gameModule.isFormModalOpen;
      },
      deleteModalInfo(state) {
        return state.modals.isDeleteModalOpen[this.$route.params.moduleName];
      },
      gameModuleData(state) {
        return state.gameModule.moduleInfo[this.$route.params.moduleName];
      },
      currencyOptions(state) {
        return state.gameModule.moduleInfo[this.$route.params.moduleName]
          && state.gameModule.moduleInfo[this.$route.params.moduleName].currencyOptions;
      },
      moduleTotalBlock(state) {
        return state.gameModule.moduleInfo[`${this.$route.params.moduleName}-total`];
      },
      totalRowInfoClientBalance(state) {
        return state.gameModule.moduleInfo[this.$route.params.moduleName]
          && state.gameModule.moduleInfo[this.$route.params.moduleName].total
          && state.gameModule.moduleInfo[this.$route.params.moduleName].total.results;
      },
      totalRowInfo(state) {
        return state.gameModule.moduleInfo[this.$route.params.moduleName]
          && state.gameModule.moduleInfo[this.$route.params.moduleName].total
          && [state.gameModule.moduleInfo[this.$route.params.moduleName].total.results];
      },
      ordering(state) {
        // eslint-disable-next-line max-len
        return state.gameModule.moduleInfo[this.$route.params.moduleName] && state.gameModule.moduleInfo[this.$route.params.moduleName].ordering;
      },
      searchFields(state) {
        // eslint-disable-next-line max-len
        return state.gameModule.moduleInfo[this.$route.params.moduleName] && state.gameModule.moduleInfo[this.$route.params.moduleName].search_fields;
      },
      defaultFilterValues(state) {
        // eslint-disable-next-line max-len
        return (state.gameModule.moduleInfo[this.$route.params.moduleName] && state.gameModule.moduleInfo[this.$route.params.moduleName].defaultFilterValues) || {};
      },
      searchBar(state) {
        // eslint-disable-next-line max-len
        return state.gameModule.moduleInfo[this.$route.params.moduleName] && state.gameModule.moduleInfo[this.$route.params.moduleName].searchBar;
      },
      expandedRowQueryData(state) {
        // eslint-disable-next-line max-len
        return state.gameModule.moduleInfo[this.$route.params.moduleName] && state.gameModule.moduleInfo[this.$route.params.moduleName].expandedRowQueryData;
      },
      gameModule(state) {
        // eslint-disable-next-line max-len
        return state.gameModule.moduleInfo[this.$route.params.moduleName] && state.gameModule.moduleInfo[this.$route.params.moduleName].results;
      },
      tableFooterArray(state) {
        // eslint-disable-next-line max-len
        return state.gameModule.moduleInfo[this.$route.params.moduleName] && state.gameModule.moduleInfo[this.$route.params.moduleName].tableFooterArray;
      },
      bulkDelete(state) {
        // eslint-disable-next-line max-len
        return state.gameModule.moduleInfo[this.$route.params.moduleName] && state.gameModule.moduleInfo[this.$route.params.moduleName].bulkDelete;
      },
      columns(state) {
        // eslint-disable-next-line max-len
        return state.gameModule.moduleInfo[this.$route.params.moduleName] && state.gameModule.moduleInfo[this.$route.params.moduleName].columns;
      },
      actions(state) {
        // eslint-disable-next-line max-len
        return state.gameModule.moduleInfo[this.$route.params.moduleName] && state.gameModule.moduleInfo[this.$route.params.moduleName].actions;
      },
      rows(state) {
        // eslint-disable-next-line max-len
        return state.gameModule.moduleInfo[this.$route.params.moduleName] && state.gameModule.moduleInfo[this.$route.params.moduleName].results.data;
      },
      footerInfo(state) {
        // eslint-disable-next-line max-len
        return state.gameModule.moduleInfo[this.$route.params.moduleName] && state.gameModule.moduleInfo[this.$route.params.moduleName].footer_info;
      },
    }),
    oldFilterValues() {
      return { ...this.defaultFilterValues, ...this.filterFormData };
    },
    isTableTranslatable() {
      if (this.columns) {
        let translatable = false;
        this.columns.forEach((col) => {
          if (col.translatable) {
            translatable = true;
          }
        });
        return translatable;
      }
      return false;
    },
    actionCodes() {
      return (this.actions && this.actions.map((action) => action.action)) || [];
    },
  },
  watch: {
    async $route() {
      if (!this.gameModule) {
        this.filterValue = {};
        await this.getGameModule();
      }
      this.setSettings();
    },
  },
  async mounted() {
    if (!this.gameModule) {
      await this.getGameModule();
      if (this.$route.params.moduleName === 'withdraw-requests') {
        await this.$store.dispatch('gameModule/getModuleTotalBlock',
          { moduleName: 'withdraw-requests' });
      }
    }
    this.setSettings();
  },
  methods: {
    async perPageSelectChange() {
      await this.getGameModule();
      this.setSettings();
    },
    async searchByFilters() {
      await this.getGameModule();
      this.setSettings();
    },
    onRangeFilterInputChange(data) {
      this.rangeFiltersValues = {
        ...this.rangeFiltersValues,
        ...data,
      };
    },
    async resetSearchFields() {
      await this.onFilterFormSearch({});
    },
    async onFilterFormSearch(filterFormData) {
      this.filterFormData = { ...filterFormData };
      await this.getGameModule();
      this.setSettings();
    },
    async updateAfterAction() {
      await this.getGameModule();
      this.setSettings();
    },
    async tableActionHandler(action) {
      this.isLoading = true;
      try {
        const res = await axios.get(`${this.$route.path}/${action}`);
        await this.getGameModule();
        this.isLoading = false;
        return res.data;
      } catch (e) {
        this.isLoading = false;
        socketError({
        }, e);
        return false;
      }
    },
    async tableWithInputActionHandler(inputValue, action) {
      this.isLoading = true;
      try {
        const res = await axios.get(`${this.$route.path}/${action}`, {
          params: {
            value: inputValue,
          },
        });
        await this.getGameModule();
        this.isLoading = false;
        return res.data;
      } catch (e) {
        this.isLoading = false;
        socketError({
        }, e);
        return false;
      }
    },
    async reindexAll() {
      this.isLoading = true;
      try {
        const res = await axios.get(`${this.$route.path}/reindex-all`);
        this.isLoading = false;
        return res.data;
      } catch (e) {
        this.isLoading = false;
        socketError({
        }, e);
        return false;
      }
    },
    async searchByOrder(data) {
      this.orderParams = data;
      await this.getGameModule();
      this.setSettings();
    },
    async searchByFilterValue(e) {
      this.filterValue = e;
      await this.getGameModule();
      this.setSettings();
    },
    async pageChange(e) {
      this.page = e;
      await this.getGameModule();
      this.setSettings();
    },
    async toggleFormModal(action) {
      await this.$store.dispatch('gameModule/toggleFormModal', action);
      await this.getGameModule();
      this.setSettings();
    },
    async toggleIframeModal() {
      await this.$store.dispatch('modals/toggleIframeModal');
    },
    async toggleEditFormModal() {
      await this.$store.dispatch('modals/toggleEditFormModal');
      const query = _.clone(this.$route.query);
      delete query.modalId;
      await this.$router.replace({ query });
      await this.getGameModule();
      this.setSettings();
    },
    setRangeFiltersValues() {
      this.rangeFiltersValues = (this.gameModuleData && this.gameModuleData.rangeFiltersValues);
    },
    setFilterFormDataValues() {
      this.filterFormData = (this.gameModuleData && this.gameModuleData.filterFormData);
    },
    setOldSelectedCurrency() {
      this.selectedCurrency = (this.gameModuleData && this.gameModuleData.oldSelectedCurrency);
    },
    setSettings() {
      this.setRangeFiltersValues();
      this.setFilterFormDataValues();
      this.setOldSelectedCurrency();
    },
    async getGameModule() {
      if (!this.isLoading) {
        this.isLoading = true;
        const params = {
          orderParams: this.orderParams,
          path: this.$route.path,
          moduleName: this.$route.params.moduleName,
          page: this.$route.query.page || 1,
          id: this.$route.params.id,
          perPage: localStorage.getItem('tablePerPage') || this.$route.query.perPage || 10,
          rangeFiltersValues: this.rangeFiltersValues,
          filterFormData: this.filterFormData,
          currency: this.selectedCurrency,
          ...this.filterValue,
        };
        await this.$store.dispatch('gameModule/getGameModule', params);
        this.isLoading = false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.game-module-wrapper{
  .page-header-wrapper{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    background: #fff;
    padding-bottom: 10px;
    padding-right: 24px;
    padding-top: 10px;
    .lang-comp-wrapper{
      display: flex;
      align-items: center;
      >span{
        margin-right: 10px;
        font-size: 12px;
      }
    }
  }
  .game-module {
    padding: 24px;

    .range-filter-wrapper {
      .range-filter {
        margin-bottom: 10px;
      }

      margin: auto;
      text-align: right;

      button {
        margin: 20px 0;
      }
    }

    .button-wrapper {
      span {
        margin: 0 4px;
        font-size: 14px!important;
      }
    }
  }
}
</style>
