import Vue from 'vue';
import VueRouter from 'vue-router';
import Dashboard from '../views/Dashboard';
import Reports from '../views/Reports';
import Player from '../views/Player';
import ExactClientViewPage from '../views/ExactClientViewPage';
import BlotExactClientViewPage from '../views/BlotExactClientViewPage';
import GameModule from '../views/GameModule';
import GameModuleViewPage from '../components/GameModuleViewPage';
import SportReports from '../views/SportReports';
import ClientCommentsView from '../views/ClientCommentsView';
import BetShops from '../views/BetShops';
import ClientVerification from '../views/ClientVerification';
import JsonEditor from '../views/JsonEditor';
import CategoryOrders from '../views/CategoryOrders';
import FormModule from '../views/FormModule';
import CollapseReport from '../views/CollapseReport';
import WheelControl from '../views/WheelControl';
import GoogleAuthPage from '../views/GoogleAuthPage';
// import PartnerPage from '../views/PartnerPage';
import NotFound from '../views/NotFound';
import PartnerPage from '../views/PartnerPage';
import FilePrintView from '../views/FilePrintView';
// import Charts from '../components/Charts/Charts';
// import EditFormPage from '../views/EditFormPage';
// import CreateFormPage from '../views/CreateFormPage';

Vue.use(VueRouter);

const routes = [
  {
    path: '/login',
    name: 'login',
    meta: { layout: 'Empty' },
    // eslint-disable-next-line import/extensions
    component: () => import('../views/Login.vue'),
  },
  {
    path: '/print-view',
    name: 'printView',
    meta: { layout: 'Empty', auth: true },
    component: FilePrintView,
  },
  {
    path: '/',
    name: 'Dashboard',
    meta: { layout: 'Main', auth: true },
    component: Dashboard,
  },
  {
    path: '/new-dashboard',
    name: 'New Dashboard',
    meta: { layout: 'Main', auth: true, breadcrumb: [] },
    component: Dashboard,
  },
  {
    path: '/google-authentication',
    name: 'Google Authentication',
    meta: {
      layout: 'Main',
      auth: true,
      breadcrumb: [
        {
          moduleName: 'googleAuthentication',
          type: 'text',
          text: 'Google Authenticator Settings',
        },
      ],
    },
    component: GoogleAuthPage,
  },
  {
    path: '/change-password',
    name: 'changePassword',
    meta: {
      layout: 'Main',
      auth: true,
      breadcrumb: [
        {
          moduleName: 'changePassword',
          type: 'text',
          text: 'Password Change',
        },
      ],
    },
    // eslint-disable-next-line import/extensions
    component: () => import('../views/ChangePassword.vue'),
  },
  // {
  //   path: '/charts',
  //   name: 'ChartContainer',
  //   meta: { layout: 'Main', auth: true },
  //   component: Charts,
  // },
  {
    path: '/admin/total-financial-reports',
    name: 'Total Financial Reports',
    meta: { layout: 'Main', auth: true, breadcrumb: [] },
    component: Dashboard,
  },
  {
    path: '/admin/total-clients-balance-reports',
    name: 'Total Clients Balance Reports',
    meta: { layout: 'Main', auth: true },
    component: Dashboard,
  },
  {
    path: '/admin/wheel-control',
    name: 'Wheel',
    meta: {
      layout: 'Main',
      auth: true,
      breadcrumb: [
        {
          moduleName: 'wheel-control',
          type: 'text',
          text: 'Wheel Control',
        },
      ],
    },
    component: WheelControl,
  },
  {
    path: '/admin/dashboard-sport-widget',
    name: 'Dashboard Sport Widget',
    meta: { layout: 'Main', auth: true, breadcrumb: [] },
    component: Dashboard,
  },
  {
    path: '/admin/dashboard-sport-widget',
    name: 'Dashboard Sport Widget',
    meta: { layout: 'Main', auth: true, breadcrumb: [] },
    component: Dashboard,
  },
  {
    path: '/dashboard',
    name: 'Dashboard1',
    meta: {
      layout: 'Main',
      auth: true,
    },
    component: Dashboard,
  },
  {
    path: '/partner/:id/:menuItem',
    name: 'PartnerPage',
    meta: {
      layout: 'Main',
      withoutTab: true,
      auth: true,
      breadcrumb: [
        {
          type: 'text',
          text: 'Partner',
          path: [
            { type: 'text', key: 'partner' },
          ],
        },
        { type: 'text', key: 'id', prefix: 'ID: ' },
        { type: 'text', key: 'menuItem' },
      ],
    },
    component: PartnerPage,
  },
  {
    path: '/player/:module',
    name: 'Player',
    meta: {
      layout: 'Main',
      auth: true,
      breadcrumb: [
        {
          type: 'text',
          key: 'module',
        },
      ],
    },
    component: Player,
  },
  {
    path: '/player/:module/:id',
    name: 'ExactClientViewPage1',
    meta: {
      layout: 'Main',
      auth: true,
      breadcrumb: [
        {
          type: 'link',
          key: 'module',
          path: [
            { type: 'text', key: 'player' },
            { type: 'link', key: 'module' },
          ],
        },
        { type: 'text', key: 'id', prefix: 'ID: ' },
      ],
    },
    component: process.env.VUE_APP_BLOT_CONTROL === 'switch_blot' ? BlotExactClientViewPage : ExactClientViewPage,
  },
  {
    path: '/player/:module/:id/:menuItem',
    name: 'ExactClientViewPage',
    meta: {
      layout: 'Main',
      withoutTab: true,
      auth: true,
      breadcrumb: [
        {
          type: 'link',
          key: 'module',
          path: [
            { type: 'text', key: 'player' },
            { type: 'link', key: 'module' },
          ],
        },
        { type: 'text', key: 'id', prefix: 'ID: ' },
        { type: 'text', key: 'menuItem' },
      ],
    },
    component: process.env.VUE_APP_BLOT_CONTROL === 'switch_blot' ? BlotExactClientViewPage : ExactClientViewPage,
  },
  {
    path: '/player/:module/:id/:menuItem/:clientCommentId',
    name: 'ClientCommentsView',
    meta: {
      layout: 'Main',
      auth: true,
      breadcrumb: [
        {
          type: 'link',
          key: 'module',
          path: [
            { type: 'text', key: 'player' },
            { type: 'link', key: 'module' },
          ],
        },
        { type: 'text', key: 'id', prefix: 'ID: ' },
        {
          type: 'link',
          key: 'menuItem',
          path: [
            { type: 'text', key: 'player' },
            { type: 'link', key: 'module' },
            { type: 'link', key: 'id' },
          ],
        },
        { type: 'text', key: 'clientCommentId', prefix: 'Client Comment ID: ' },
      ],
    },
    component: ClientCommentsView,
  },
  {
    path: '/admin/report-totals',
    name: 'CollapseReport',
    meta: {
      layout: 'Main',
      auth: true,
      breadcrumb: [
        { type: 'text', text: 'Total Report' },
      ],
    },
    component: CollapseReport,
  },
  {
    path: '/admin/bonus-mass-assign',
    name: 'BonusMassAssign',
    meta: {
      moduleName: 'bonus-mass-assign',
      layout: 'Main',
      auth: true,
      breadcrumb: [
        { type: 'text', text: 'Bonus Mass Assign' },
      ],
    },
    component: FormModule,
  },
  {
    path: '/admin/refund',
    name: 'Refund',
    meta: {
      moduleName: 'refund',
      layout: 'Main',
      auth: true,
      breadcrumb: [
        { type: 'text', text: 'Refund' },
      ],
    },
    component: FormModule,
  },
  {
    path: '/admin/payout-autoconfirm-configs',
    name: 'payout-autoconfirm-configs',
    meta: {
      moduleName: 'payout-autoconfirm-configs',
      layout: 'Main',
      auth: true,
      breadcrumb: [
        { type: 'text', text: 'Payout AutoConfirm Configs' },
      ],
    },
    component: FormModule,
  },
  {
    path: '/admin/addPlayerToTournament',
    name: 'addPlayerToTournament',
    meta: {
      moduleName: 'addPlayerToTournament',
      layout: 'Main',
      auth: true,
      breadcrumb: [
        { type: 'text', text: 'Prepare addPlayerToTournament' },
      ],
    },
    component: FormModule,
  },
  {
    path: '/admin/removePlayerToTournament',
    name: 'removePlayerToTournament',
    meta: {
      moduleName: 'removePlayerToTournament',
      layout: 'Main',
      auth: true,
      breadcrumb: [
        { type: 'text', text: 'Prepare removePlayerToTournament' },
      ],
    },
    component: FormModule,
  },
  {
    path: '/admin/prepare-wheel',
    name: 'PrepareWheel',
    meta: {
      moduleName: 'prepare-wheel',
      layout: 'Main',
      auth: true,
      breadcrumb: [
        { type: 'text', text: 'Prepare Wheel' },
      ],
    },
    component: FormModule,
  },
  {
    path: '/admin/verification-request',
    name: 'VerificationRequest',
    meta: {
      moduleName: 'verification-request',
      layout: 'Main',
      auth: true,
      breadcrumb: [
        { type: 'text', text: 'Verification Request' },
      ],
    },
    component: FormModule,
  },
  {
    path: '/admin/site-self-exclusions',
    name: 'SiteSelfExclusions',
    meta: {
      moduleName: 'site-self-exclusions',
      layout: 'Main',
      auth: true,
      breadcrumb: [
        { type: 'text', text: 'Site Exclusion Settings' },
      ],
    },
    component: FormModule,
  },
  {
    path: '/admin/categories-orders',
    name: 'CategoryOrders',
    meta: {
      layout: 'Main',
      auth: true,
      breadcrumb: [
        { type: 'text', text: 'Category Orders' },
      ],
    },
    component: CategoryOrders,
  },
  {
    path: '/admin/limit-site',
    name: 'LimitBySite',
    meta: {
      moduleName: 'limitBySite',
      layout: 'Main',
      auth: true,
      breadcrumb: [
        { type: 'text', text: 'Limit By Site' },
      ],
    },
    component: FormModule,
  },
  {
    path: '/admin/site-menu',
    name: 'JsonEditor',
    meta: {
      layout: 'Main',
      auth: true,
      breadcrumb: [
        { type: 'text', text: 'Site Menu' },
      ],
    },
    component: JsonEditor,
  },
  {
    path: '/admin/translate',
    name: 'JsonEditorForTranslation',
    meta: {
      layout: 'Main',
      auth: true,
      breadcrumb: [
        { type: 'text', text: 'Translate' },
      ],
    },
    component: JsonEditor,
  },
  {
    path: '/admin/payment-forms',
    name: 'PaymentFormJsonEditor',
    meta: {
      layout: 'Main',
      auth: true,
      breadcrumb: [
        { type: 'text', text: 'Payment Form' },
      ],
    },
    component: JsonEditor,
  },
  {
    path: '/admin/client-verification/:id/verification',
    name: 'ClientVerification',
    meta: {
      layout: 'Main',
      auth: true,
      breadcrumb: [
        {
          type: 'link',
          text: 'Client Verification',
          path: [
            { type: 'text', key: 'admin/client-verification' },
          ],
        },
        { type: 'text', key: 'id', prefix: 'ID: ' },
      ],
    },
    component: ClientVerification,
  },
  {
    path: '/admin/bet-shops/clients-transactions/:id',
    name: 'BetShops',
    meta: {
      layout: 'Main',
      auth: true,
      breadcrumb: [
        {
          type: 'link',
          text: 'Bet Shops',
          path: [
            { type: 'text', key: 'admin/bet-shops' },
          ],
        },
        { type: 'text', text: 'Client Tsansactions' },
        { type: 'text', key: 'id', prefix: 'ID: ' },
      ],
    },
    component: BetShops,
  },
  {
    path: '/admin/:moduleName/:action/:id',
    name: 'GameModuleViewPage1',
    meta: {
      layout: 'Main',
      auth: true,
      breadcrumb: [
        {
          type: 'link',
          key: 'moduleName',
          path: [
            { type: 'link', key: 'moduleName', pathPrefix: 'admin/' },
          ],
        },
        { type: 'text', key: 'id', prefix: 'View ID: ' },
      ],
    },
    component: GameModuleViewPage,
  },
  {
    path: '/admin/:moduleName',
    name: 'GameModule',
    meta: {
      layout: 'Main',
      auth: true,
      breadcrumb: [
        {
          type: 'text',
          key: 'moduleName',
        },
      ],
    },
    component: GameModule,
  },
  // {
  //   path: '/admin/:moduleName/:action',
  //   name: 'CreateFormPage',
  //   meta: { layout: 'Main', auth: true },
  //   component: GameModule,
  // },
  {
    path: '/admin/:moduleName/:id/:action',
    name: 'GameModule1',
    meta: {
      layout: 'Main',
      auth: true,
    },
    component: GameModule,
  },
  {
    path: '/admin/:moduleName/:id',
    name: 'GameModuleViewPage',
    meta: {
      layout: 'Main',
      auth: true,
      breadcrumb: [
        {
          type: 'link',
          key: 'moduleName',
          path: [
            { type: 'link', key: 'moduleName', pathPrefix: 'admin/' },
          ],
        },
        { type: 'text', key: 'id', prefix: 'View ID: ' },
      ],
    },
    component: GameModuleViewPage,
  },
  {
    path: '/reports',
    name: 'Report',
    meta: { layout: 'Main', auth: true },
    component: Reports,
  },
  {
    path: '/reports/sport/reports-by-sport',
    name: 'SportReports',
    meta: {
      layout: 'Main',
      auth: true,
      breadcrumb: [
        { type: 'text', text: 'The Report Of Sport' },
      ],
    },
    component: SportReports,
  },
  {
    path: '/reports/:reportGroup',
    name: 'ReportGroup',
    meta: {
      layout: 'Main',
      auth: true,
      breadcrumb: [
        { type: 'text', key: 'reportGroup' },
      ],
    },
    component: Reports,
  },
  {
    path: '/reports/:reportGroup/:report',
    name: 'Report',
    meta: {
      layout: 'Main',
      auth: true,
      breadcrumb: [
        { type: 'text', key: 'reportGroup' },
        { type: 'text', key: 'report' },
      ],
    },
    component: Reports,
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // eslint-disable-next-line import/extensions
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
  },
  {
    path: '/*',
    name: 'Not Found',
    meta: { layout: 'Main', auth: true, tabTitle: 'Not Found' },
    component: NotFound,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

// router.beforeEach((to, from, next) => {
//   console.log(to, from);
//   console.log('toooooooooooooo', to);
//   next(() => ({
//     ...to,
//     meta: { ...to.meta, withoutTab: false },
//   }));
// });

export default router;
