<template>
  <a-card style="min-width: 28%" class="client-info-card" :class="{midSize}">
    <div class="header">
      <a-avatar v-if="!midSize"
                :size="80"
                :src="data.avatar"
                icon="user"
      />
      <div class="header-right">
        <div class="user-name">
          <span>{{ data.name }}</span>
          <span v-if="!hideEditBtn">
            <a-button
              style="margin-left: 10px"
              type="dashed"
              size="small"
              @click="openClientEditModal"
            >
              Edit
            </a-button>
          </span>
          <span>
            <a-button type="link" size="small">
              <router-link to="/player/client">
                Return to List
              </router-link>
            </a-button>
          </span>
          <a-tooltip placement="topLeft">
            <template slot="title">
              <span>Generate reset password link</span>
            </template>
            <span
              v-if="data.password_reset_button"
              role="button"
              style="cursor: pointer"
              @click="resetPasswordLink"
            >
              <a-icon type="sync" :spin="isResetPassLoading" style="color: #111212ba" />
            </span>
          </a-tooltip>
        </div>
        <div class="action-wrapper">
          <slot />
        </div>
      </div>
    </div>
    <div class="footer">
      <div class="footer-row">
        <span class="contact-details">
          <a-icon type="idcard" />
          {{ data.external_id }}
        </span>
        <span class="border" />
        <span class="contact-details">
          <a-icon type="global" />
          {{ data.address }}
        </span>
        <span class="border" />
        <span class="contact-details">
          <a-icon type="phone" />
          {{ data.phone }}
        </span>
      </div>
      <div class="footer-row">
        <span class="contact-details">
          <a-icon type="credit-card" />
          {{ data.birthDay && isNaN(data.birthDay) ? moment(data.birthDay).format('LL') :
            (data.birthDay && data.birthDay.length > 10
              && moment(JSON.parse(data.birthDay)).format('LL')
              || (data.birthDay && moment(JSON.parse(data.birthDay) * 1000).format('LL')))
            || null
          }}
        </span>
        <span class="border" />
        <span class="contact-details">
          <a-icon type="mail" />
          {{ data.email }}
        </span>
      </div>
      <div v-if="data.tags" class="footer-tag-row">
        <span>
          <a-popover v-for="(tag) in data.tags" :key="tag.id">
            <template slot="title">
              <router-link
                :to="`/admin/client-tags/${tag.id}`"
              >
                {{ tag.name }}
              </router-link>
            </template>
            <a-button
              shape="circle"
              size="small"
              :style="{margin: '0 6px', backgroundColor: tag.color}"
            />
          </a-popover>
        </span>
      </div>
    </div>
  </a-card>
</template>

<script>
import socketError from '../../../../utils/socketError';

export default {
  props: {
    data: {
      type: Object,
      default() {
        return {};
      },
    },
    midSize: {
      type: Boolean,
      default: false,
    },
    hideEditBtn: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      moment,
      isResetPassLoading: false,
    };
  },
  methods: {
    async resetPasswordLink() {
      if (!this.isResetPassLoading) {
        this.isResetPassLoading = true;
        try {
          const res = await axios.get(`/player/client/${this.$route.params.id}/reset-password-link`);
          this.isResetPassLoading = false;
          if (res.data && res.data.link) {
            await navigator.clipboard.writeText(res.data.link).then(() => {
              this.$message.success(
                'The link was copied',
                5,
              );
            });
          }
        } catch (e) {
          this.isResetPassLoading = false;
          socketError({}, e);
        }
      }
    },
    async openClientEditModal() {
      const params = {
        id: this.$route.params.id,
      };
      await this.$store.dispatch('modals/toggleClientEditFormModal', params);
      const query = _.clone(this.$route.query);
      query.modalId = this.dataRowCell;
      // eslint-disable-next-line eqeqeq
      if (this.$route.query.modalId != this.$route.params.id) {
        await this.$router.push({ query });
      }
    },
  },
};
</script>
<style lang="scss">
.client-info-card{
  &.ant-card{
    /*border-radius: 2px!important;*/
    /*box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.25);*/
    /*border-radius: 16px!important;*/
    &.midSize{
      margin-bottom: 6px;
      .ant-card-body {
        padding: 10px 12px 0 12px !important;
        .header{
          margin-bottom: 0;
          .header-right{
            margin-left: 0;
            display: flex;
            justify-content: space-between;
            button{
              font-size: 12px;
            }
            .action-wrapper{
              flex-wrap: wrap;
              button{
                margin-bottom: 8px;
              }
            }
          }
        }
        .footer {
          .footer-row{
            flex-wrap: wrap;
            margin-bottom: 0;
          }
        }
      }
    }
    .ant-card-body {
      padding: 16px!important;
      .header{
        display: flex;
        align-items: center;
        margin-bottom: 12px;
        .header-right{
          margin-left: 16px;
          flex-grow: 1;
          .action-wrapper{
            display: flex;
            .verify-button-wrapper{
              font-weight: 600;
              margin-right: 12px;
              .risk-status-btn{
                border: 1px solid #1890FF!important;
              }
            }
          }
        }
        .user-name{
          text-align: left;
          font-size: 16px;
          margin-bottom: 4px;
          color: black;
        }
      }
      .footer{
        div:first-child{
          margin-bottom: 8px;
        }
        .footer-tag-row {
          margin-top: 8px;
          display: flex;
          justify-content: flex-end;
        }
        .footer-row{
          display: flex;
          align-items: center;
          .border{
            border-right: 1px solid #E9E9E9;
            height: 15px;
            margin: 0 10px;
          }
          .contact-details{
            display: flex;
            align-items: center;
            i {
              margin-right: 8px;
              padding-bottom: 2px;
            }
          }
        }
      }
    }
  }
}
</style>
