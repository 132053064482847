<template>
  <div ref="tiny-editor-wrapper" class="tiny-editor-wrapper">
    <a-form-item
      v-if="translatable"
      :validate-status="validatingStatus"
      :help="validateHelp"
      :html-for="'d1'+ fieldName"
      class="form-item-holder"
    >
      <template slot="label">
        <a-tag color="blue">
          {{ formLocaleLang }}
        </a-tag>
        <span class="form-item-label-text">{{ label }}</span>
      </template>
      <div :class="{error: validatingStatus==='error'}">
        <editor v-if="tinyMounted"
                :id="'d1'+ fieldName"
                ref="tm"
                :value="translatableValue[lang]"
                api-key="meh3qjtt8e71r37qwbm7ckegbm3e00lmwxfrwqt40hho4tf4"
                :init="{
                  height: 250,
                  min_height: 150,
                  menubar: true,
                  toolbar2: 'undo redo | fontsizeselect ' +
                    'formatselect | bold italic  strikethrough  forecolor backcolor ' +
                    '| link | alignleft aligncenter alignright alignjustify  ' +
                    '| numlist bullist outdent indent  | removeformat' +
                    '| code | preview fullscreen ',
                  plugins: [
                    'advlist autolink template lists link image charmap print preview anchor',
                    'searchreplace visualblocks code fullscreen',
                    'insertdatetime media table paste code help wordcount'
                  ],
                  templates: editorTemplates,
                }"
                @editorInit="changeLanguageForUpdateEditor"
                @input="(val) => onTranslatableValueChange(lang, val)"
        />
      </div>
    </a-form-item>
    <a-form-item
      v-else
      :validate-status="validatingStatus"
      :help="validateHelp"
      class="form-item-holder"
      :html-for="'d1'+ fieldName"
    >
      <template slot="label">
        <span class="form-item-label-text">{{ label }}</span>
      </template>
      <div :class="{error: validatingStatus==='error'}">
        <editor v-if="tinyMounted"
                :id="'d1'+ fieldName"
                ref="tm"
                :value="value"
                api-key="meh3qjtt8e71r37qwbm7ckegbm3e00lmwxfrwqt40hho4tf4"
                :init="{
                  height: 250,
                  min_height: 150,
                  menubar: true,
                  toolbar2: 'undo redo | fontsizeselect ' +
                    'formatselect | bold italic  strikethrough  forecolor backcolor ' +
                    '| link | alignleft aligncenter alignright alignjustify  ' +
                    '| numlist bullist outdent indent  | removeformat' +
                    '| code | preview fullscreen ',
                  plugins: [
                    'advlist autolink template lists link image charmap print preview anchor',
                    'searchreplace visualblocks code fullscreen',
                    'insertdatetime media table paste code help wordcount'
                  ],
                  templates: editorTemplates,
                }"
                @input="onEditorInputChange"
        />
      </div>
    </a-form-item>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import Editor from '@tinymce/tinymce-vue';

export default {
  components: {
    editor: Editor,
  },
  // eslint-disable-next-line vue/require-prop-types
  props: ['oldValue', 'label', 'type', 'fieldName', 'translation', 'translatable', 'validationError', 'isValidationFirstError', 'formLocaleLang'],
  data() {
    return {
      value: '',
      tinyMounted: false,
      validatingStatus: 'validating',
      lang: '',
      validateHelp: '',
      translatableValue: {},
      editorTemplates: [
        {
          title: 'Insert Accordion',
          description: 'accordion template',
          content: '<h1 class="custom-accordion">[[accordion_start]]<div>Accordion Title</div><p>Fill The Accordion Text</p>[[accordion_end]]</h1>',
        },
        // {
        //   title: 'Define Accordion Start',
        //   description: 'accordion template',
        //   content: '<div class="custom-accordion">[[accordion_start]]',
        // },
        // {
        //   title: 'Define Accordion Content',
        //   description: 'accordion template',
        //   content: '<div>Accordion Title</div><div>Fill The Accordion Text</div></div>',
        // },
        // {
        //   title: 'Define Accordion End',
        //   description: 'accordion template',
        //   content: '[[accordion_end]]</div>',
        // },
        {
          title: 'Add Button',
          description: 'Add Button',
          content: '<button>Button text</button>',
        },
      ],
    };
  },
  computed: {
    ...mapState({
      langs(state) {
        return state.languages;
      },
    }),
  },
  watch: {
    formLocaleLang(e) {
      this.lang = e;
    },
    isValidationFirstError(e) {
      if (e) {
        this.$refs['tiny-editor-wrapper'].scrollIntoView();
      }
    },
    validationError(e) {
      if (e) {
        this.validatingStatus = 'error';
        // eslint-disable-next-line prefer-destructuring
        this.validateHelp = e[0];
      } else {
        this.deleteValidationErrors();
      }
    },
  },
  created() {
    if (this.translatable) {
      this.setTranslatableValues();
    }
  },
  async mounted() {
    this.lang = this.formLocaleLang;
    this.$nextTick(() => {
      this.tinyMounted = true;
      if (this.translatableValue) {
        this.changeLanguageForUpdateEditor();
      }
    });
    if (this.translatable) {
      this.langs.forEach((lang) => {
        this.onTranslatableValueChange(lang);
      });
    } else {
      setTimeout(() => {
        if (this.oldValue) {
          this.value = this.oldValue;
          this.$refs.tm.editor.setContent(this.oldValue);
          this.$emit('input-change', { [this.fieldName]: this.oldValue });
        }
      }, 500);
    }
  },
  methods: {
    deleteValidationErrors() {
      if (this.validatingStatus === 'error') {
        this.validatingStatus = 'validating';
        this.validateHelp = '';
      }
    },
    onEditorInputChange(value) {
      this.value = value;
      this.$emit('input-change', { [this.fieldName]: value });
      this.deleteValidationErrors();
    },
    async changeLanguageForUpdateEditor() {
      if (this.formLocaleLang !== 'am') {
        this.lang = 'am';
      } else {
        this.lang = 'en';
      }
      setTimeout(() => {
        this.lang = this.formLocaleLang;
      }, 60);
    },
    onTranslatableValueChange(lang, val) {
      if (val !== undefined) {
        this.translatableValue[lang] = val;
      }
      if (lang === 'default') {
        this.$emit('input-change', { [this.fieldName]: this.translatableValue[lang] || '' });
        this.deleteValidationErrors();
      } else {
        const obj = {
          lang,
          info: {
            column_name: this.fieldName,
            value: this.translatableValue[lang],
          },
        };
        this.$nextTick(() => {
          this.$emit('translation-input-change', obj);
          this.deleteValidationErrors();
        });
      }
    },
    setTranslatableValues() {
      const translatableValue = {};
      // translatableValue[formLocaleLang][fieldName]
      this.langs.forEach((lang) => {
        if (lang === 'default') {
          translatableValue[lang] = this.oldValue || '';
        } else {
          translatableValue[lang] = (this.translation
            && this.getTranslation(this.translation, this.fieldName, lang)) || '';
        }
      });
      this.translatableValue = translatableValue;
    },
  },
};
</script>

<style scoped lang="scss">
.tiny-editor-wrapper{
  .error{
    border-bottom: 1px solid red;
  }
  .label-input{
    font-size: 14px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.85);
  }
}
</style>
<style>
.tox-notifications-container { display: none !important }
</style>
