var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{key:_vm.$route.path,staticClass:"game-module-wrapper"},[_c('div',{staticClass:"page-header-wrapper"},[(_vm.isTableTranslatable)?_c('div',{staticClass:"lang-comp-wrapper"},[_c('span',{},[_vm._v("Language")]),_c('LanguageSwitcher')],1):_vm._e(),(_vm.searchFields)?_c('SearchBar',{attrs:{"columns":_vm.searchFields,"search-bar":_vm.searchBar,"is-loading":_vm.isLoading},on:{"search-by-filter-value":_vm.searchByFilterValue}}):_vm._e()],1),_c('div',{staticClass:"game-module"},[_c('div',[(_vm.gameModuleData && _vm.gameModuleData.range_filter)?_c('div',{staticClass:"range-filter-wrapper"},[_vm._l((_vm.gameModuleData.range_filter),function(filter,index){return _c('RangeFilter',{key:index,attrs:{"is-loading":_vm.isLoading,"field":filter.field,"label":filter.label,"to-val":_vm.rangeFiltersValues[filter.field + '_to'] || '',"from-val":_vm.rangeFiltersValues[filter.field + '_from'] || ''},on:{"on-range-filter-input-change":_vm.onRangeFilterInputChange}})}),_c('a-button',{attrs:{"type":"primary","icon":"search"},on:{"click":_vm.searchByFilters}},[_vm._v(" Search ")])],2):_vm._e(),_c('div',[(_vm.gameModuleData && _vm.gameModuleData.filter)?_c('FilterForm',{key:JSON.stringify(_vm.oldFilterValues),attrs:{"filter-list":_vm.gameModuleData.filter,"own-submit":true,"is-loading":_vm.isLoading,"old-form-values":_vm.oldFilterValues,"has-reset-button":true},on:{"reset-search-fields":_vm.resetSearchFields,"filter-form-search":_vm.onFilterFormSearch}}):_vm._e()],1),(['cash-logs', 'cashback-checker'].includes(
        _vm.$route.params.moduleName)
        && _vm.gameModuleData && _vm.gameModuleData.total)?_c('div',[_c('AntdTable',{attrs:{"loading":_vm.isLoading,"columns":_vm.gameModuleData.total.columns,"rows":_vm.totalRowInfo}})],1):_vm._e(),(['client-balance-snapshots', 'bet-shops'].includes(
             _vm.$route.params.moduleName)
             && _vm.gameModuleData && _vm.gameModuleData.total)?_c('div',{staticStyle:{"margin-bottom":"20px"}},[_c('AntdTable',{attrs:{"loading":_vm.isLoading,"columns":_vm.gameModuleData.total.columns,"rows":_vm.totalRowInfoClientBalance}})],1):_vm._e(),(['withdraw-requests'].includes(
        _vm.$route.params.moduleName) && _vm.moduleTotalBlock)?_c('div',[_c('AntdTable',{attrs:{"loading":_vm.isLoading,"columns":_vm.moduleTotalBlock.columns,"rows":_vm.moduleTotalBlock.results}})],1):_vm._e(),_c('div',[_c('AntdTable',{attrs:{"columns":_vm.columns,"rows":_vm.rows,"no-refresh":_vm.actionCodes.includes('reindex-all'),"order-params":_vm.ordering,"bulk-delete":_vm.bulkDelete,"table-title":_vm.gameModule && _vm.gameModule.tableTitle,"loading":_vm.isLoading,"search-params":{
            ..._vm.filterFormData,
            ..._vm.filterValue
          },"page-count":Math.ceil(_vm.gameModule && _vm.gameModule.total / _vm.gameModule.per_page),"total":_vm.gameModule && _vm.gameModule.total,"page-value":_vm.gameModule && _vm.gameModule.current_page,"per-page":_vm.gameModule && _vm.gameModule.per_page,"pagination":_vm.gameModule && _vm.gameModule.per_page && _vm.rows.length >= _vm.gameModule.per_page
            || (_vm.gameModule && _vm.gameModule.last_page_url),"translatable":_vm.gameModuleData && _vm.gameModuleData.translatable,"footer-info":_vm.footerInfo,"table-footer":_vm.tableFooterArray,"expanded-row-query-data":_vm.expandedRowQueryData},on:{"on-page-change":_vm.pageChange,"per-page-select-change":_vm.perPageSelectChange,"search-by-order":_vm.searchByOrder,"update-table":_vm.getGameModule}},[(_vm.actions && _vm.actions[0])?_c('div',{staticClass:"button-wrapper"},_vm._l((_vm.actions),function(action,index){return _c('span',{key:index},[(action.action === 'create')?_c('a-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.toggleFormModal('create')}}},[_vm._v(" + "+_vm._s(action.label)+" ")]):(action.action === 'Create Wheel')?_c('a-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.toggleFormModal('create')}}},[_c('router-link',{attrs:{"to":"/admin/wheel-control"}},[_vm._v(" + "+_vm._s(action.label)+" ")])],1):(action.action === 'reindex-all')?_c('a-button',{on:{"click":_vm.reindexAll}},[_vm._v(" "+_vm._s(action.label)+" ")]):(action.action === 'update_filtered')?_c('a-button',{attrs:{"type":"dashed"},on:{"click":function($event){return _vm.toggleFormModal('update_filtered')}}},[_vm._v(" "+_vm._s(action.label)+" ")]):(action.type === 'withInput')?_c('a-input-search',{staticStyle:{"max-width":"400px"},attrs:{"placeholder":action.inputPlaceholder || '',"type":action.inputType || 'text',"default-value":action.inputDefaultValue || ''},on:{"search":(inputValue) => _vm.tableWithInputActionHandler(inputValue, action.action)}},[_c('a-button',{attrs:{"slot":"enterButton","type":"primary"},slot:"enterButton"},[_vm._v(" "+_vm._s(action.label)+" ")])],1):_c('a-button',{on:{"click":function($event){return _vm.tableActionHandler(action.action)}}},[_vm._v(" "+_vm._s(action.label)+" ")])],1)}),0):_vm._e(),(_vm.gameModuleData && _vm.gameModuleData.printable)?_c('PrintFile',{attrs:{"search-params":{
              orderBy: _vm.ordering && _vm.ordering.orderBy || 'ASC',
              column: _vm.ordering && _vm.ordering.column || '',
              perPage: 5000,
              ..._vm.oldFilterValues,
              ..._vm.filterValue,
              currency: _vm.selectedCurrency || '',
            }}}):_vm._e(),_c('div',{staticStyle:{"margin":"0 10px"}},[(_vm.gameModuleData && _vm.gameModuleData.download_token)?_c('DownLoadFile',{attrs:{"total-count":_vm.gameModule && _vm.gameModule.total,"search-params":{
                            orderBy: _vm.ordering && _vm.ordering.orderBy || 'ASC',
                            column: _vm.ordering && _vm.ordering.column || '',
                            reportName: _vm.$route.path,
                            perPage: 10000000,
                            token: _vm.gameModuleData && _vm.gameModuleData.download_token,
                            ..._vm.oldFilterValues,
                            ..._vm.filterValue,
                            currency: _vm.selectedCurrency || '',
                          }}}):_vm._e()],1),(_vm.currencyOptions)?_c('div',[_c('a-select',{staticStyle:{"width":"120px","margin-right":"14px"},attrs:{"placeholder":"Currency"},on:{"change":_vm.getGameModule},model:{value:(_vm.selectedCurrency),callback:function ($$v) {_vm.selectedCurrency=$$v},expression:"selectedCurrency"}},_vm._l((_vm.currencyOptions),function(option){return _c('a-select-option',{key:option.code,attrs:{"disabled":option.disabled}},[_vm._v(" "+_vm._s(option.label)+" ")])}),1)],1):_vm._e()],1)],1),(_vm.$route.params.moduleName === 'withdraw-requests'
        && _vm.gameModuleData && _vm.gameModuleData['total_result']
        && _vm.gameModuleData['total_result_columns'])?_c('div',[_c('AntdTable',{attrs:{"loading":_vm.isLoading,"columns":_vm.gameModuleData['total_result_columns'],"rows":[_vm.gameModuleData['total_result']]}})],1):_vm._e()]),(_vm.deleteModalInfo)?_c('DeleteModal',{attrs:{"delete-vuex-action":"gameModule/deleteSelectedUser","info":_vm.deleteModalInfo},on:{"update-table-info":_vm.updateAfterAction}}):_vm._e(),(Boolean(_vm.isFormModalOpen))?_c('CreateFormPage',{attrs:{"visible":Boolean(_vm.isFormModalOpen),"action":_vm.isFormModalOpen || '',"search-params":{
        ..._vm.filterFormData,
        ..._vm.filterValue
      }},on:{"toggle-modal":_vm.toggleFormModal}}):_vm._e(),_c('IframeModal',{attrs:{"visible":Boolean(_vm.isIframeModalOpen),"req-url":_vm.isIframeModalOpen},on:{"toggle-modal":_vm.toggleIframeModal}}),(Boolean(_vm.isEditFormModalOpen))?_c('EditFormPage',{attrs:{"visible":_vm.isEditFormModalOpen,"type":"edit","update-dispatch-action":"gameModule/updateModuleById","unmount-action":"gameModule/deleteEditFormJson","get-edit-form-dispatch-action":"gameModule/editModuleById","state-name":"gameModule","request-path":"/admin","id-of-edit-modal":_vm.isEditFormModalOpen},on:{"toggle-modal":_vm.toggleEditFormModal}}):_vm._e(),(_vm.deleteFormModalInfo)?_c('DeleteFormModal',{attrs:{"delete-vuex-action":"gameModule/softDeleteById","info":_vm.deleteFormModalInfo,"form-route":"soft-delete-form"},on:{"update-table-info":_vm.updateAfterAction}}):_vm._e(),(_vm.isRejectModalOpen)?_c('RejectModal',{attrs:{"modal-form-content":_vm.isRejectModalOpen},on:{"update-table-info":_vm.updateAfterAction}}):_vm._e(),(_vm.isImageModalOpen)?_c('ViewImageModal',{attrs:{"info":_vm.isImageModalOpen}}):_vm._e(),(_vm.isApproveModalOpen)?_c('ApproveModal',{attrs:{"id":_vm.isApproveModalOpen}}):_vm._e(),(_vm.isChangePassModalOpen)?_c('ChangePassModal',{attrs:{"id":_vm.isChangePassModalOpen}}):_vm._e(),(_vm.dynamicFormModalInfo)?_c('DynamicFormModal',{attrs:{"modal-form-info":_vm.dynamicFormModalInfo},on:{"update-table-info":_vm.getGameModule}}):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }