<template>
  <div>
    <AntdModal
      text="Are You Sure You want to Save"
      ok-text="Save"
      :visible="isSaveModalOpen"
      @handle-save="onJsonSave"
      @close-modal="() => (isSaveModalOpen = false)"
    />
    <LanguageSwitcher />
    <vue-json-editor
      v-model="json"
      :show-btns="true"
      :expanded-on-start="true"
      @json-change="onJsonChange"
      @json-save="openModal"
    />
  </div>
</template>

<script>
import vueJsonEditor from 'vue-json-editor';
import socketError from '../utils/socketError';
import LanguageSwitcher from '../components/partial/LanguageSwitcher';
import AntdModal from '../components/partial/Modals/AntdModal';

export default {
  components: {
    vueJsonEditor,
    LanguageSwitcher,
    AntdModal,
  },
  data() {
    return {
      json: {},
      isSaveModalOpen: false,
    };
  },
  watch: {
    async localeLang() {
      await this.getJson();
    },
  },
  async mounted() {
    await this.getJson();
  },
  methods: {
    onJsonChange() {
    },
    openModal() {
      this.isSaveModalOpen = true;
    },
    async onJsonSave() {
      try {
        const res = await axios.post(`${this.$route.path}/update`, {
          lang: this.localeLang,
          jsonData: JSON.stringify(this.json),
        });
        if (res.data.errors) {
          this.errors = res.data.errors;
        } else {
          this.errors = {};
        }
        this.isSaveModalOpen = false;
        return res.data;
      } catch (e) {
        socketError({
        }, e);
        return false;
      }
    },
    async getJson() {
      try {
        const res = await axios.get(this.$route.path, {
          params: {
            lang: this.localeLang,
          },
        });
        this.json = res.data.results;
        return res.data;
      } catch (e) {
        socketError({}, e);
        return false;
      }
    },
  },
};
</script>
