import { render, staticRenderFns } from "./GoogleAuthPage.vue?vue&type=template&id=2b2d0577"
import script from "./GoogleAuthPage.vue?vue&type=script&lang=js"
export * from "./GoogleAuthPage.vue?vue&type=script&lang=js"
import style0 from "./GoogleAuthPage.vue?vue&type=style&index=0&id=2b2d0577&prod&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports