<template>
  <div class="category-orders">
    <a-spin :spinning="isLoading">
      <a-tabs
        v-model="activeKey"
        :tab-bar-style="{background: 'white', borderRadius: '6px'}"
        @change="handleTabChange"
      >
        <a-tab-pane v-for="{ label, code} in categoriesOrderTabs" :key="code" :tab="label">
          <DragAbleCategoriesOrders
            :categories-order="categoriesOrder"
          />
        </a-tab-pane>
      </a-tabs>
    </a-spin>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import DragAbleCategoriesOrders from '../components/DragAbleOrders/DragAbleCategoriesOrders';

export default {
  components: {
    DragAbleCategoriesOrders,
  },
  data() {
    return {
      isLoading: false,
      activeKey: '',
    };
  },
  computed: {
    ...mapState({
      categoriesOrder(state) {
        return state.gameModule.categoriesOrder[this.$route.query.type]
          && state.gameModule.categoriesOrder[this.$route.query.type].results;
      },
      categoriesOrderTabs(state) {
        return state.gameModule.categoriesOrder && state.gameModule.categoriesOrder.tabs;
      },
    }),
  },
  watch: {
    async $route() {
      if (!this.categoriesOrder) {
        await this.getCategoriesOrder();
      }
    },
  },
  async mounted() {
    if (!this.$route.query.type) {
      await this.$router.push({ query: { type: 'casino' } });
    }
    if (!this.categoriesOrder) {
      await this.getCategoriesOrder();
    }
    this.activeKey = this.$route.query.type;
  },
  methods: {
    async handleTabChange(e) {
      await this.$router.push({ query: { type: e } });
    },
    async getCategoriesOrder() {
      if (!this.isLoading) {
        this.isLoading = true;
        const params = {
          path: this.$route.path,
          type: this.$route.query.type,
        };
        await this.$store.dispatch('gameModule/getCategoriesOrder', params);
        this.isLoading = false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.category-orders {
  padding: 24px 20px;
}
</style>
