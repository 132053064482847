<template>
  <div ref="input-select" class="input-select">
    <a-form-item
      :validate-status="validatingStatus"
      :help="validateHelp"
      class="form-item-holder"
    >
      <template slot="label">
        <span class="form-item-label-text">{{ label }}</span>
      </template>
      <a-select
        v-model="selectedValue"
        :value="selectedValue"
        :disabled="disabled === 'disabled'"
        style="min-width: 200px"
        :allow-clear="allowClear"
      >
        <a-select-option v-for="(option, index) in options"
                         :key="index"
                         :value="option.code"
                         :disabled="disabled && disabled.includes(option.code)"
        >
          {{ option.label }}
        </a-select-option>
      </a-select>
    </a-form-item>
  </div>
</template>
<script>
import socketError from '../../utils/socketError';

export default {
  // eslint-disable-next-line vue/require-prop-types
  props: ['type', 'label', 'fieldName', 'relation', 'values', 'oldValue', 'validationError', 'isValidationFirstError', 'serverSideDependence', 'disabled', 'relationId', 'allowClear', 'requestMethod'],
  data() {
    return {
      options: [],
      selectedValue: '',
      relationValues: [],
      validatingStatus: 'validating',
      validateHelp: '',
    };
  },
  watch: {
    async serverSideDependence(e, prevValue) {
      if (e && Object.keys(e)[0] && (Object.values(prevValue)[0] !== Object.values(e)[0])) {
        await this.setOptions();
      }
    },
    isValidationFirstError(e) {
      if (e) {
        this.$refs['input-select'].scrollIntoView();
      }
    },
    validationError(e) {
      if (e) {
        this.validatingStatus = 'error';
        // eslint-disable-next-line prefer-destructuring
        this.validateHelp = e[0];
      } else {
        this.deleteValidationErrors();
      }
    },
    selectedValue(selectedValue) {
      // let code;
      // // eslint-disable-next-line max-len
      // eslint-disable-next-line max-len
      // const optionLabels = this.values && this.values[0] ? [...this.values] : [...this.relationValues];
      // optionLabels.forEach((elem) => {
      //   if (elem.label === selectedValue) {
      //     code = elem.code;
      //   }
      // });
      this.$emit('input-change', { [this.fieldName]: selectedValue });
    },
  },
  mounted() {
    this.setOptions();
    if (this.oldValue) {
      this.$emit('input-change', { [this.fieldName]: this.oldValue });
    }
  },
  methods: {
    deleteValidationErrors() {
      this.validatingStatus = 'validating';
      this.validateHelp = '';
    },
    async getValues() {
      try {
        const id = this.$route.params.moduleName === 'menu-items' ? `/${this.$route.query.modalId}` : '';
        const params = {
          type: this.type,
          ...this.serverSideDependence,
        };
        if (this.relationId) {
          params.relationId = this.relationId;
        }
        if (this.requestMethod === 'post') {
          const res = await axios.post(`${this.$route.path}/relation/${this.relation}${id}`, {
            ...params,
          });
          return res.data;
        }
        const res = await axios.get(`${this.$route.path}/relation/${this.relation}${id}`, {
          params,
        });
        return res.data;
      } catch (e) {
        socketError({}, e);
        return false;
      }
    },
    async setOptions() {
      if (this.relation) {
        this.relationValues = await this.getValues();
        if (this.relationValues && this.relationValues[0]) {
          this.options = [...this.relationValues];
          this.relationValues.forEach(
            (item) => {
              if (this.oldValue === item.code) {
                this.selectedValue = item.code;
              }
              return item.code;
            },
          );
        }
      } else {
        this.options = [...this.values];
        this.values.forEach(
          (item) => {
            if (this.oldValue === item.code) {
              this.selectedValue = item.code;
            }
            return item.code;
          },
        );
      }
    },
  },
};
</script>

<style scoped lang="scss">
  label {
    display: block;
  }
  .input-select{
    .select-label {
      font-size: 14px!important;
      font-weight: 400!important;
      color: rgba(0, 0, 0, 0.85);
    }
  }
</style>
