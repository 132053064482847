<template>
  <div id="app">
    <div v-if="isPreLoading" class="spinner-wrapper">
      <a-spin size="large" />
    </div>
    <div v-else>
      <a-back-top>
        <div class="ant-back-top-inner back-top">
          UP
        </div>
      </a-back-top>
      <component :is="layout">
        <router-view />
      </component>
    </div>
  </div>
</template>

<script>
import MainLayout from './layouts/MainLayout';
import EmptyLayout from './layouts/EmptyLayout';
import EditPageWithHeaderLayout from './layouts/EditPageWithHeaderLayout';

// const pjson = require('../package.json');
//
// const consoleCss = 'text-shadow: 10px 10px 25px rgb(81,67,21),\n'
//   + '    -10px 10px 25px rgb(81,67,21),\n'
//   + '    -10px -10px 25px rgb(81,67,21),\n'
//   + '    10px -10px 25px rgb(81,67,21);\n'
//   + '-webkit-text-stroke: 1px #F8F8F8;font-style: oblique;font-size: 40px';
export default {
  components: {
    EmptyLayout, MainLayout, EditPageWithHeaderLayout,
  },
  data() {
    return {
      isPreLoading: true,
    };
  },
  computed: {
    isLanguageRequestOn() {
      return process.env.VUE_APP_BLOT_CONTROL !== 'switch_blot';
    },
    layout() {
      const custom = Number(this.$route.query['edit-view']) === 1 ? 'EditPageWithHeaderLayout' : null;
      return custom || (this.$route.meta.layout || 'Empty') + 'Layout';
    },
  },
  async mounted() {
    // if (window.console) {
    //   // eslint-disable-next-line no-console
    //   console.log(`%cSmartbet v${pjson.version} `, consoleCss);
    // }
    let success = false;
    if (localStorage.getItem('token')) {
      success = await this.$store.dispatch('info/fetchUserInfo');
    }
    if (!success && this.$route.name !== 'login') {
      await this.$router.push('/login');
    }
    if (success && this.isLanguageRequestOn) {
      await this.$store.dispatch('getLanguages');
    }
    this.notificationMiddleware();
    this.isPreLoading = false;
  },
  methods: {
    notificationMiddleware() {
      axios.interceptors.response.use((response) => {
        if (response && response.data && response.data.alert) {
          if (response.data.alert !== 'error') {
            this.openNotificationWithIcon('success', response.data.message || response.data.alert);
          } else {
            this.openNotificationWithIcon('error', response.data.message || response.data.alert);
          }
        }
        if (response && response.headers['backend-version']) {
          this.checkBackEndVersion(response.headers['backend-version']);
        }
        return response;
      }, (error) => {
        // eslint-disable-next-line max-len
        if (error && error.response && error.response.status === 401) {
          this.openNotificationWithIcon('error', 'Please log in to continue');
          setTimeout(() => {
            window.location.reload();
          }, 1000);
          return false;
        }
        if (error && error.response && error.response.status === 404) {
          if (error.response.data.alert) {
            this.openNotificationWithIcon(error.response.data.alert, error.response.data.message);
          } else {
            this.openNotificationWithIcon('error', 'The page is not found');
          }
          // const targetPath = this.$route.path;
          // this.$router.push({
          //   component: 'NotFound',
          //   path: `/not-found${targetPath}`,
          // }).then(() => {
          //   this.$store.dispatch('tabsVuex/removeTab', { key: targetPath });
          // });
          return error;
        }
        if (error && error.response && error.response.data && error.response.data.alert) {
          this.openNotificationWithIcon(error.response.data.alert, error.response.data.message);
          return error;
        }
        // eslint-disable-next-line max-len
        if (error && error.response && error.response.data && error.response.data.payload && error.response.data.payload.message) {
          this.openNotificationWithIcon('error', error.response.data.payload.message);
          return false;
        }
        this.openNotificationWithIcon('error', error);
        return false;
      });
    },
    openNotificationWithIcon(type, message, duration = 4) {
      this.$notification[type]({
        message: type,
        top: '20%',
        duration,
        description: message,
      });
    },
    checkBackEndVersion(currentVersion) {
      const registeredVersion = localStorage.getItem('controlBackVersion');
      if (!registeredVersion) {
        localStorage.setItem('controlBackVersion', currentVersion);
        return;
      }
      if (currentVersion && currentVersion !== registeredVersion) {
        const message = 'Changed Control Version,Please Refresh the page to get New version';
        this.openNotificationWithIcon('warning', message, 5000);
        localStorage.removeItem('controlBackVersion');
      }
    },
  },
};
</script>

<style lang="scss">
#app {
  /*font-family: Avenir, Helvetica, Arial, sans-serif;*/
  font-family: Ubuntu, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  .spinner-wrapper{
    display: flex;
    height: 100vh;
    justify-content: center;
    align-items: center;
  }
  .back-top{
    height: 40px;
    width: 40px;
    line-height: 40px;
    border-radius: 4px;
    background-color: #1088e9;
    color: #fff;
    text-align: center;
    font-size: 20px;
  }
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

</style>
