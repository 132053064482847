<template>
  <div class="filter-form">
    <a-form class="ant-advanced-search-form form-controller"
            :layout="viewConfig.layout"
            :wrapper-col="viewConfig.wrapperCol"
            :label-col="viewConfig.labelCol"
            :whitespace="true"
            :colon="true"
            @submit="handleSubmit"
    >
      <div class="form-row-wrapper">
        <a-row
          v-for="(row, rowInd) in orderedFilterList"
          :key="row.type"
          :class="row.type"
        >
          <a-col v-for="(val, key, i) in row.filters"
                 :key="String(key)"
                 :style="{ display: !expand && (i > 3 || rowInd > 0) ? 'none' : '' }"
                 :xl="columnCountInRow(val.type) || 8"
                 :xxl="columnCountInRow(val.type) || 6"
                 :lg="columnCountInRow(val.type) || 12"
                 :md="columnCountInRow(val.type) || 12"
                 :sm="columnCountInRow(val.type) || 24"
                 :xs="columnCountInRow(val.type) || 24"
          >
            <div
              v-if="val.type === 'checkbox' && (!val.depends_on || dependOnChecker(val.depends_on))"
              class="comp-wrapper"
            >
              <Checkbox
                :label="val.label || key"
                :field-name="key"
                :old-value="oldFormValues[key]"
                @input-change="inputChange"
              />
            </div>
            <div
              v-else-if="val.type === 'text'
                && (!val.depends_on || dependOnChecker(val.depends_on))"
              class="comp-wrapper"
            >
              <Input
                :label="val.label || key"
                :field-name="key"
                :old-value="oldFormValues[key]"
                :validation-error="validationErrors[key]"
                @input-change="inputChange"
              />
            </div>
            <div
              v-else-if="val.type === 'ant-date-range'
                && (!val.depends_on || dependOnChecker(val.depends_on))"
              class="comp-wrapper"
            >
              <AntDateRangePicker
                :label="val.label || key"
                :default-value="val.defaultValue"
                :show-time="val.showTime"
                :dis-allow-clear="val.disAllowClear"
                :field="key"
                :old-value="oldFormValues[key]"
                @input-change="inputChange"
              />
            </div>
            <div
              v-else-if="val.type === 'month-picker'
                && (!val.depends_on || dependOnChecker(val.depends_on))"
              class="comp-wrapper"
            >
              <MonthPicker
                :label="val.label || key"
                :field="key"
                :disabled="val.disabled"
                :old-value="oldFormValues[key]"
                :validation-error="validationErrors[key]"
                :is-validation-first-error="isValidationFirstError(validationErrors, key)"
                @input-change="inputChange"
              />
            </div>
            <div
              v-else-if="val.type === 'ant-date-range-with-timezone'
                && (!val.depends_on || dependOnChecker(val.depends_on))"
              class="comp-wrapper"
            >
              <DateRangePickerWithTimeZone
                :label="val.label || key"
                :default-value="val.defaultValue"
                :dis-allow-clear="val.disAllowClear"
                :field="key"
                :old-value="oldFormValues[key]"
                @input-change="inputChange"
              />
            </div>
            <div
              v-else-if="val.type === 'date-range-picker'
                && (!val.depends_on || dependOnChecker(val.depends_on))"
              class="comp-wrapper"
            >
              <RangeDatePicker
                :label="val.label || key"
                :default-value="val.defaultValue"
                :field="key"
                :old-value="oldFormValues[key]"
                @input-change="inputChange"
              />
            </div>
            <div
              v-else-if="val.type === 'date'
                && (!val.depends_on || dependOnChecker(val.depends_on))"
              class="comp-wrapper"
            >
              <AntDateInput
                :label="val.label || key"
                :field="key"
                :hide-time="val.hideTime"
                :dis-allow-clear="val.disAllowClear"
                :default-value="val.defaultValue"
                :old-value="oldFormValues[key]"
                @input-change="inputChange"
              />
            </div>
            <div
              v-else-if="val.type === 'only-date-custom-input'
                && (!val.depends_on || dependOnChecker(val.depends_on))"
              class="comp-wrapper"
            >
              <OnlyDateCustomInput
                :label="val.label || key"
                :field="key"
                :hide-time="val.hideTime"
                :default-value="val.defaultValue"
                :old-value="oldFormValues[key]"
                @input-change="inputChange"
              />
            </div>
            <div
              v-else-if="val.type === 'number'
                && (!val.depends_on || dependOnChecker(val.depends_on))"
              class="comp-wrapper"
            >
              <Input
                type="number"
                :label="val.label || key"
                :field-name="key"
                :old-value="oldFormValues[key]"
                @input-change="inputChange"
              />
            </div>
            <div
              v-else-if="val.type === 'color_picker'
                && (!val.depends_on || dependOnChecker(val.depends_on))"
              class="comp-wrapper"
            >
              <Input
                type="color"
                :label="val.label || key"
                :field-name="key"
                :old-value="oldFormValues[key]"
                @input-change="inputChange"
              />
            </div>
            <div
              v-else-if="val.type === 'unsigned'
                && (!val.depends_on || dependOnChecker(val.depends_on))"
              class="comp-wrapper"
            >
              <Input
                type="number"
                :min="0"
                :label="val.label || key"
                :field-name="key"
                :old-value="oldFormValues[key]"
                @input-change="inputChange"
              />
            </div>
            <div
              v-else-if="val.type === 'decimal'
                && (!val.depends_on || dependOnChecker(val.depends_on))"
              class="comp-wrapper"
            >
              <Input
                type="number"
                :step="0.01"
                :label="val.label || key"
                :field-name="key"
                :old-value="oldFormValues[key]"
                @input-change="inputChange"
              />
            </div>
            <div
              v-else-if="val.type === 'textarea'
                && (!val.depends_on || dependOnChecker(val.depends_on))"
              class="comp-wrapper"
            >
              <Input
                type="textarea"
                :label="val.label || key"
                :field-name="key"
                :old-value="oldFormValues[key]"
                @input-change="inputChange"
              />
            </div>
            <div
              v-else-if="val.type === 'file'
                && (!val.depends_on || dependOnChecker(val.depends_on))"
              class="comp-wrapper"
            >
              <Input
                type="file"
                :label="val.label || key"
                :field-name="key"
                :old-value="oldFormValues[key]"
                @input-change="inputChange"
              />
            </div>
            <div
              v-else-if="val.type === 'decimal_unsigned'
                && (!val.depends_on || dependOnChecker(val.depends_on))"
              class="comp-wrapper"
            >
              <Input
                type="number"
                :step="0.01"
                :label="val.label || key"
                :field-name="key"
                :old-value="oldFormValues[key]"
                @input-change="inputChange"
              />
            </div>
            <div
              v-else-if="val.type === 'unsigned_decimal'
                && (!val.depends_on || dependOnChecker(val.depends_on))"
              class="comp-wrapper"
            >
              <Input
                type="number"
                :min="0"
                step="0.01"
                :label="val.label || key"
                :field-name="key"
                :old-value="oldFormValues[key]"
                @input-change="inputChange"
              />
            </div>
            <div
              v-else-if="val.type === 'radio-select-unity'
                && (!val.depends_on || dependOnChecker(val.depends_on))"
              class="comp-wrapper"
            >
              <RadioSelectUnity
                :label="val.label || key"
                :field-name="key"
                :select-description="val.selectDescription"
                :radio-description="val.radioDescription"
                :old-value="oldFormValues[key]"
                @input-change="inputChange"
              />
            </div>
            <div
              v-else-if="val.type === 'radio'
                && (!val.depends_on || dependOnChecker(val.depends_on))"
              class="comp-wrapper"
            >
              <Radio
                :label="val.label || key"
                :field-name="key"
                :values="val.values"
                :old-value="oldFormValues[key]"
                @input-change="inputChange"
              />
            </div>
            <div
              v-else-if="val.type === 'select'
                && (!val.depends_on || dependOnChecker(val.depends_on))"
              class="comp-wrapper"
            >
              <Select
                :field-name="key"
                :label="val.label || key"
                :values="val.values"
                :allow-clear="val.allowClear"
                :relation="val.relation"
                :old-value="oldFormValues[key]"
                @input-change="inputChange"
              />
            </div>
            <div
              v-else-if="val.type === 'select_multiple'
                && (!val.depends_on || dependOnChecker(val.depends_on))"
              class="comp-wrapper"
            >
              <MultipleSelect
                :field-name="key"
                :label="val.label || key"
                :values="val.values"
                :relation="val.relation"
                :old-value="typeof oldFormValues[key] === 'string'
                  ? JSON.parse(oldFormValues[key]) : oldFormValues[key]"
                @input-change="inputChange"
              />
            </div>
            <div
              v-else-if="val.type === 'select_multiple_paginated'
                && (!val.depends_on || dependOnChecker(val.depends_on))"
              class="comp-wrapper"
            >
              <PaginatedMultipleSelect
                :field-name="key"
                :label="val.label || key"
                :relation="val.relation"
                :values="val.values"
                :old-value="typeof oldFormValues[key] === 'string'
                  ? JSON.parse(oldFormValues[key]) : oldFormValues[key]"
                @input-change="inputChange"
              />
            </div>
            <div
              v-else-if="val.type === 'select_paginated'
                && (!val.depends_on || dependOnChecker(val.depends_on))"
              class="comp-wrapper"
            >
              <PaginatedSelect
                :field-name="key"
                :label="val.label || key"
                :relation="val.relation"
                :values="val.values"
                :old-value="typeof oldFormValues[key] === 'string'
                  ? JSON.parse(oldFormValues[key]) : oldFormValues[key]"
                @input-change="inputChange"
              />
            </div>
            <div
              v-else-if="val.type === 'datetime'
                && (!val.depends_on || dependOnChecker(val.depends_on))"
              class="comp-wrapper"
            >
              <DatePickerInput
                :field-name="key"
                :label="val.label || key"
                :old-value="oldFormValues[key]"
                @input-change="inputChange"
              />
            </div>
            <div v-else-if="val.type === 'editor'" class="comp-wrapper">
              <TinyEditor
                type="editor"
                :label="val.label || key"
                :field-name="key"
                :old-value="oldFormValues[key]"
                @input-change="inputChange"
              />
            </div>
          </a-col>
        </a-row>
      </div>
      <div class="btn-wrapper">
        <a-button
          v-if="hasResetButton"
          :disabled="isLoading || isResetFilterLoading"
          :loading="isResetFilterLoading"
          style="margin-right: 15px"
          @click.prevent="$emit('reset-search-fields')"
        >
          Reset
        </a-button>
        <a-button
          v-if="ownSubmit"
          :disabled="isLoading || isResetFilterLoading"
          :loading="isLoading"
          type="primary"
          html-type="submit"
          @click.prevent="$emit('filter-form-search', formValue)"
        >
          Search
        </a-button>
        <a-button
          v-if="isCollapsible"
          type="link"
          class="collapse-btn"
          @click="toggleCollapse"
        >
          <span v-if="expand">
            Collapse <a-icon type="up" />
          </span>
          <span v-else>
            Expand <a-icon type="down" />
          </span>
        </a-button>
      </div>
    </a-form>
  </div>
</template>

<script>
import Checkbox from '../../FormElements/Checkbox';
import Input from '../../FormElements/Input';
import Radio from '../../FormElements/Radio';
import Select from '../../FormElements/Select';
import DatePickerInput from '../../FormElements/DatePickerInput';
import MultipleSelect from '../../FormElements/MultipleSelect';
import TinyEditor from '../../FormElements/TinyEditor';
import PaginatedMultipleSelect from '../../FormElements/PaginatedMultipleSelect';
import PaginatedSelect from '../../FormElements/PaginatedSelect';
import AntDateInput from '../../FormElements/AntDateInput';
import RangeDatePicker from '../../FormElements/RangeDatePicker';
import AntDateRangePicker from '../../FormElements/AntDateRangePicker';
import DateRangePickerWithTimeZone from '../../FormElements/DateRangePickerWithTimeZone';
import OnlyDateCustomInput from '../../FormElements/OnlyDateCustomInput';
import MonthPicker from '../../FormElements/MonthPicker';
import RadioSelectUnity from '../../FormElements/RadioSelectUnity';

export default {
  name: 'FormPage',
  components: {
    Checkbox,
    Input,
    Radio,
    Select,
    DatePickerInput,
    MultipleSelect,
    TinyEditor,
    PaginatedMultipleSelect,
    PaginatedSelect,
    AntDateInput,
    RangeDatePicker,
    AntDateRangePicker,
    DateRangePickerWithTimeZone,
    OnlyDateCustomInput,
    MonthPicker,
    RadioSelectUnity,
  },
  props: {
    filterOrdering: {
      type: Array,
      default() {
        return ['text'];
      },
    },
    validationErrors: {
      type: Object,
      default() {
        return {};
      },
    },
    oldFormValues: {
      type: Object,
      default() {
        return {};
      },
    },
    parentFormValue: {
      type: Object,
      default() {
        return {};
      },
    },
    ownSubmit: {
      type: Boolean,
      default: false,
    },
    filterList: {
      type: Object,
      default() {
        return {};
      },
    },
    fieldName: {
      type: String,
      default: '',
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    isResetFilterLoading: {
      type: Boolean,
      default: false,
    },
    hasResetButton: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      expand: false,
      labelCol: {
        span: 10,
        // xs: { span: 24, offset: 10 },
        // sm: { span: 24, offset: 10 },
        // lg: { span: 12 },
        // md: { span: 18 },
        // xl: { span: 24 },
      },
      wrapperCol: {
        span: 14,
        // xs: { span: 24, offset: 10 },
        // sm: { span: 24, offset: 10 },
        // lg: { span: 6 },
        // md: { span: 6 },
        // xl: { span: 24 },
      },
      labelAlign: 'left',
      selectedDateRange: {
        startDate: null,
        endDate: null,
      },
      isFormTranslatable: false,
      formValue: {},
    };
  },
  computed: {
    count() {
      return this.expand ? Object.keys(this.filterList).length : 4;
    },
    viewConfig() {
      // if (false) {
      //   return {
      //     layout: 'vertical',
      //     labelCol: { span: 24 },
      //     wrapperCol: { offset: 1 },
      //   };
      // }
      return {
        layout: 'horizontal',
        labelCol: { span: 24 },
        wrapperCol: { span: 18 },
      };
    },
    orderedFilterList() {
      const filterList = [];
      _.forIn(this.filterList, (value, key) => {
        let { type } = value;
        if (type === 'only-date-custom-input') {
          type = 'date';
        }
        const group = filterList.filter((item) => item.type === type)[0];
        if (group) {
          group.filters = { ...group.filters, [key]: value };
        } else {
          filterList.push(
            {
              type,
              filters: { [key]: value },
            },
          );
        }
        // filterList[type] = { ...filterList[type], [key]: value };
      });
      return filterList.sort((elem) => {
        const { type } = elem;
        if (this.filterOrdering.includes(type)) {
          return -1;
        }
        return 0;
      });
    },
    isCollapsible() {
      return Object.keys(this.orderedFilterList).length > 1 || this.filterList.length > 4;
    },
  },
  methods: {
    columnCountInRow(type) {
      if (!type) return null;
      let col;
      if (type === 'ant-date-range-width-timezone') {
        if (window.innerWidth < 1280) {
          col = 24;
        } else if (window.innerWidth >= 1280 && window.innerWidth < 1850) {
          col = 12;
        }
      } else if (type === 'radio-select-unity') {
        col = 24;
      }
      return col;
      // let col = null;
      // _.forIn(this.filterList, (value) => {
      //   const { type } = value;
      //   if (type === 'ant-date-range-width-timezone') {
      //     if (window.innerWidth < 1280) {
      //       col = 24;
      //     } else if (window.innerWidth >= 1280 && window.innerWidth < 1850) {
      //       col = 12;
      //     }
      //   }
      // });
      // console.log('col', col);
      // return col;
    },
    toggleCollapse() {
      this.expand = !this.expand;
    },
    handleSubmit() {
      if (this.ownSubmit) {
        this.$emit('filter-form-search', this.formValue);
      }
    },
    dependOnChecker(data) {
      const key = Object.keys(data)[0];
      const value = data[key];
      return this.formValue[key] === value || this.parentFormValue[key] === value;
    },
    inputChange(e) {
      this.formValue = {
        ...this.formValue,
        ...e,
      };
      this.$emit('input-change', { [this.fieldName]: this.formValue });
    },
  },
};
</script>

<style lang="scss">
.filter-form {
  margin-bottom: 16px;
  border-radius: 8px;
  background-color: #fff;
  .ant-advanced-search-form {
    padding: 20px;
  }
  .form-row-wrapper{
    display: flex;
    flex-direction: column;
    .ant-col{
      display: block;
    }
    .text{
      order: 1
    }
    .date{
      order: 2
    }
    .checkbox{
      order: 4
    }
    .select_multiple{
      order: 5
    }
    .radio{
      order: 3
    }
    .radio-select-unity{
      order: 6
    }
  }
  .btn-wrapper{
    display: flex;
    justify-content: flex-end;
    .submit-btn{
      margin-right: 12px;
    }
    .collapse-btn{
      margin-left: 5px;
      span{
        display: flex;
        align-content: center;
        i{
          margin-left: 5px;
        }
      }
    }
  }
}
.ant-col{
  /*display: flex;*/
  /*justify-content: flex-end;*/
}
.comp-wrapper{
  display: block;
  >div{
    display: block;
  }
}
</style>

<!--<style lang="scss">-->
<!--@media only screen and (min-width: 576px) {-->
<!--//under this with this form is not horizontal and this style is for long labels white space-->
<!--.form-item-holder {-->
<!--  display: flex;-->

<!--  .ant-form-item-label {-->
<!--    line-height: initial;-->
<!--  }-->

<!--  .ant-form-item-label label {-->
<!--    display: flex;-->
<!--    align-items: center;-->
<!--    height: 100%;-->
<!--    margin: 0;-->
<!--  }-->

<!--  .form-item-label-text {-->
<!--    width: 100%;-->
<!--    display: block;-->
<!--    text-align: right;-->
<!--    line-height: 1.3;-->
<!--    font-size: 14px !important;-->
<!--    font-weight: 400 !important;-->
<!--    color: rgba(0, 0, 0, 0.85);-->
<!--    white-space: pre-line;-->
<!--    overflow-wrap: break-word;-->

<!--  }-->
<!--}-->
<!--}-->
<!--</style>-->
