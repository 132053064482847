<template>
  <a-col span="16">
    <div style="display: flex; justify-content: center">
      <a-tabs v-model="activeKeyLocal"
              hide-add
              :animated="true"
              type="editable-card"
              @edit="onEdit"
      >
        <a-tab-pane v-for="pane in panes"
                    :key="pane.key"
                    :closable="pane.closable"
        >
          <template slot="tab">
            <span @click.middle="() => onMouseMiddleRemove(pane.key)">{{ pane.title }}</span>
          </template>
        </a-tab-pane>
      </a-tabs>
    </div>
  </a-col>
</template>
<script>
import { mapState } from 'vuex';

export default {
  data() {
    // const panes = [
    //   { title: 'Tab 1', content: 'Content of Tab 1', key: '1asd' },
    //   { title: 'Tab 2', content: 'Content of Tab 2', key: '2' },
    //   {
    //     title: 'Tab 3', content: 'Content of Tab 3', key: '3', closable: false,
    //   },
    // ];
    return {
      activeKeyLocal: '',
    };
  },
  computed: {
    ...mapState({
      activeKey(state) {
        return state.tabsVuex.activeKey;
      },
      panes(state) {
        return state.tabsVuex.panes;
      },
      newTabIndex(state) {
        return state.tabsVuex.newTabIndex;
      },
    }),
    tabTitle() {
      let title = this.$route.params.menuItem || this.$route.params.module
        || this.$route.params.moduleName || this.$route.params.report
        || this.$route.params.reportGroup || this.$route.path;
      title = title.replace(title.charAt(0), title[0].toUpperCase());
      return title;
    },
  },
  watch: {
    activeKey(e) {
      this.activeKeyLocal = e;
    },
    activeKeyLocal(key) {
      this.activeKeyChange(key);
    },
    async $route(to, from) {
      if (to.path !== from.path) {
        let existence = false;
        this.panes.forEach((tab) => {
          if (tab.key === this.$route.fullPath) {
            existence = true;
          }
        });
        if (this.$route.meta.withoutTab) {
          return;
        }
        if (!existence) {
          await this.$store.dispatch('tabsVuex/addTab', { key: this.$route.fullPath, title: this.tabTitle });
        } else {
          this.activeKeyLocal = this.$route.fullPath;
        }
      }
    },
  },
  async mounted() {
    let existence = false;
    this.panes.forEach((tab) => {
      if (tab.key === this.$route.fullPath) {
        existence = true;
      }
    });
    if (!existence) {
      await this.$store.dispatch('tabsVuex/addTab', { key: this.$route.fullPath, title: this.tabTitle });
    }
  },
  methods: {
    async activeKeyChange(key) {
      if (this.$route.fullPath !== key) {
        await this.$router.push(key);
        await this.$store.dispatch('tabsVuex/activeKeyChange', key);
      }
    },
    callback() {
    },
    async onMouseMiddleRemove(targetKey) {
      await this.$store.dispatch('tabsVuex/removeTab', { key: targetKey });
    },
    async onEdit(targetKey, action) {
      // eslint-disable-next-line max-len
      // await this.$store.dispatch(`tabsVuex/${action}Tab`, { key: targetKey || this.$route.path, title: this.$route.path });
      if (action === 'remove') {
        // this[action](targetKey);
        await this.$store.dispatch(`tabsVuex/${action}Tab`, { key: targetKey });
      }
    },
    add() {
      const { panes } = this;
      const activeKey = `newTab${this.newTabIndex++}`;
      panes.push({ title: 'New Tab', content: 'Content of new Tab', key: activeKey });
      this.panes = panes;
      this.activeKey = activeKey;
    },
    remove(targetKey) {
      let { activeKey } = this;
      let lastIndex;
      this.panes.forEach((pane, i) => {
        if (pane.key === targetKey) {
          lastIndex = i - 1;
        }
      });
      const panes = this.panes.filter((pane) => pane.key !== targetKey);
      if (panes.length && activeKey === targetKey) {
        if (lastIndex >= 0) {
          activeKey = panes[lastIndex].key;
        } else {
          activeKey = panes[0].key;
        }
      }
      this.panes = panes;
      this.activeKey = activeKey;
    },
  },
};
</script>

<style lang="scss">
.ant-tabs-bar{
margin: 0!important;
}
</style>
