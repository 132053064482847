<template>
  <a-col
    :span="8"
    :xs="24"
    :md="12"
    :xl="8"
  >
    <a-spin :spinning="isLoading">
      <div class="widget-card-wrapper">
        <a-card v-if="isChartActive && hasChart">
          <template slot="title">
            <div class="card-heading">
              <span class="card-title">
                {{ title }}
              </span>
              <span @click="widgetTabChange">
                <a-icon
                  type="file"
                  style="font-size: 20px; margin: 0 8px; cursor: pointer"
                />
              </span>
              <span>
                <a-icon
                  type="line-chart"
                  style="
                    font-size: 20px;
                    color: #1890FF;
                    margin-right: 10px;
                  "
                />
              </span>
              <DateRangePicker
                v-model="selectedChartDate"
                :ranges="presetList"
                :auto-apply="true"
                :always-show-calendars="false"
                date-util="moment"
                :close-on-esc="true"
                :opens="pickerPosition"
                @select="updateChartDatePicker"
                @toggle="toggle"
              >
                <template #input="picker">
                  <i class="fa fa-calendar-alt" />
                  {{ labelCreate( picker) }}
                </template>
              </DateRangePicker>
              <a-icon
                type="sync"
                :spin="isLoading"
                class="refresh-icon"
                :class="{ error: errorInLoad}"
                @click="() => getWidgetChartData()"
              />
              <!--            <button-->
              <!--              :class="{ error: errorInLoad}"-->
              <!--              class="btn btn-sm"-->
              <!--              @click="() => init()"-->
              <!--            >-->
              <!--              <a-icon type="sync" :spin="isLoading" />-->
              <!--              <i class="fa fa-sync" />-->
              <!--            </button>-->
            </div>
          </template>
          <HighChart
            :chart-type="chartType"
            :chart-series="widgetChartData && widgetChartData[selectedChartByField]
              && widgetChartData[selectedChartByField].chartData"
            :selected-chart-by-field="selectedChartByField"
          />
          <div style="display: flex; justify-content: space-between">
            <div>
              <a-select
                v-model="selectedChartByField"
                style="width: 100px"
                @change="handleChartChangeByField"
              >
                <a-select-option v-for="option in chartListByField"
                                 :key="option.code"
                                 :value="option.code"
                >
                  {{ option.label }}
                </a-select-option>
              </a-select>
            </div>
            <div>
              Chart Type
              <a-select
                v-model="chartType"
                style="width: 80px; margin-left: 8px"
              >
                <a-select-option value="line">
                  Line
                </a-select-option>
                <a-select-option value="spline">
                  Spline
                </a-select-option>
                <a-select-option value="column">
                  Column
                </a-select-option>
                <a-select-option value="area">
                  Area
                </a-select-option>
                <a-select-option value="areaspline">
                  AreaSpline
                </a-select-option>
                <a-select-option value="scatter">
                  Scatter
                </a-select-option>
                <a-select-option value="bar">
                  Bar
                </a-select-option>
              </a-select>
            </div>
          </div>
        </a-card>
        <a-card v-else>
          <template slot="title">
            <div class="card-heading">
              <span class="card-title">
                {{ title }}
              </span>
              <div v-if="hasChart">
                <a-icon
                  type="file"
                  theme="twoTone"
                  style="font-size: 20px; margin: 0 8px;"
                />
                <span @click="widgetTabChange">
                  <a-icon
                    type="line-chart"
                    style="cursor: pointer; font-size: 20px;"
                  />
                </span>
              </div>
              <DateRangePicker
                v-model="selected"
                :ranges="presetList"
                :auto-apply="true"
                :always-show-calendars="false"
                date-util="moment"
                :close-on-esc="true"
                :opens="pickerPosition"
                @select="updateDatePicker"
                @toggle="toggle"
              >
                <template #input="picker">
                  <i class="fa fa-calendar-alt" />
                  {{ labelCreate( picker) }}
                </template>
              </DateRangePicker>
              <a-icon
                type="sync"
                :spin="isLoading"
                class="refresh-icon"
                :class="{ error: errorInLoad}"
                @click="() => init()"
              />
            <!--            <button-->
            <!--              :class="{ error: errorInLoad}"-->
            <!--              class="btn btn-sm"-->
            <!--              @click="() => init()"-->
            <!--            >-->
            <!--              <a-icon type="sync" :spin="isLoading" />-->
            <!--              <i class="fa fa-sync" />-->
            <!--            </button>-->
            </div>
          </template>
          <div class="inner">
            <div class="card-row">
              <div
                v-for="(infoRow,key) in widgetInfoForGrid"
                :key="key"
              >
                <div v-if="infoRow === 'hidden'">
                  <div />
                  <div />
                </div>
                <div v-else class="row-info">
                  <div class="row-key">
                    {{ key }}
                  </div>
                  <div>{{ infoRow }}</div>
                </div>
              </div>
            </div>
          </div>
        </a-card>
      </div>
    </a-spin>
  </a-col>
</template>
<script>
import 'vue-select/src/scss/vue-select.scss';
import DateRangePicker from 'vue2-daterange-picker';
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';
import { mapState } from 'vuex';
import HighChart from './Charts/HighChart';
import DateRangePickerInput from './DateRangePickerInput';

export default {
  components: { DateRangePicker, HighChart },
  props: {
    ind: {
      type: Number,
      default: 0,
    },
    title: {
      type: String,
      default: '',
    },
    hasChart: {
      type: Boolean,
      default: false,
    },
    presetList: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      // presetList: {},
      selected: { startDate: null, endDate: null },
      selectedChartDate: { startDate: null, endDate: null },
      displayPresetName: DateRangePickerInput,
      pickerPosition: 'center',
      isChartActive: false,
      selectedChartByField: '',
      chartType: 'column',
    };
  },
  computed: {
    dashboardName() {
      return this.$route.path.substring(1) || 'dashboard';
    },
    ...mapState({
      errorInLoad(state) {
        return state.dashboard.widgetStates[this.dashboardName][this.title].error;
      },
      isLoading(state) {
        // eslint-disable-next-line
        const isLoading = state.dashboard.widgetStates[this.dashboardName][this.title].isLoading;

        window.onbeforeunload = () => {
          if (this.isLoading) {
            return `Are you sure you want to reload this page, getting ${this.title} data in process`;
          }

          return null;
        };

        return isLoading;
      },
      widgetInfo(state) {
        return state.dashboard.widgetInfo[this.dashboardName][this.title]
          && state.dashboard.widgetInfo[this.dashboardName][this.title].widgetInfo;
      },
      oldSelectedDate(state) {
        return state.dashboard.widgetInfo[this.dashboardName][this.title]?.oldSelectedDate;
      },
      chartListByField(state) {
        return state.dashboard.widgetChartData[this.title]
          && state.dashboard.widgetChartData[this.title].chartListByField;
      },
      widgetChartData(state) {
        return state.dashboard.widgetChartData[this.title];
      },
    }),
    widgetInfoForGrid() {
      const size = _.size(this.widgetInfo);
      const mutatedInfo = {};
      if (!size) {
        return mutatedInfo;
      }

      Object.keys(this.widgetInfo).forEach((key) => {
        if (Array.isArray(this.widgetInfo[key])) {
          let str = '';
          this.widgetInfo[key].forEach((elem, index) => {
            if (index === 0) {
              str += elem;
            } else if (index === 2) {
              str = `${str} (${elem}%)`;
            } else {
              str = str + ' / ' + elem;
            }
          });
          mutatedInfo[key] = str;
        } else {
          mutatedInfo[key] = this.widgetInfo[key];
        }
      });
      const additionalItemsNeeded = 3 - (size % 3);
      const additionalItems = {};
      if (additionalItemsNeeded === 3) return mutatedInfo;

      for (let i = 0; i < additionalItemsNeeded; i++) {
        additionalItems['hidden_' + i] = 'hidden';
      }
      return { ...mutatedInfo, ...additionalItems };
    },
  },
  async mounted() {
    if (_.isEmpty(this.widgetInfo)) {
      this.setFirstLoadDate();
      this.init();
    } else {
      this.setOldDateValues();
    }
  },
  methods: {
    updateChartDatePicker(val) {
      this.getWidgetChartData(val);
    },
    updateDatePicker(val) {
      this.init(val);
    },
    toggle(e) {
      if (e) {
        if (window.innerWidth >= 768 && this.ind % 3 === 0) {
          this.pickerPosition = 'left';
        } else if (window.innerWidth >= 576 && window.innerWidth < 768 && this.ind % 2 === 0) {
          this.pickerPosition = 'left';
        } else if (window.innerWidth < 576) {
          this.pickerPosition = 'left';
        } else if (window.innerWidth >= 768 && this.ind % 3 === 1) {
          this.pickerPosition = 'right';
        } else if (window.innerWidth >= 576 && window.innerWidth < 768 && this.ind % 2 === 1) {
          this.pickerPosition = 'right';
        }
      }
    },
    labelCreate(picker) {
      let label = _.findKey(picker.ranges,
        (o) => (o[0].isSame(picker.startDate)
          && o[1].isSame(picker.endDate)));
      if (!label && picker.startDate && picker.endDate) {
        label = `${picker.startDate.toLocaleDateString('en-US')} - ${picker.endDate.toLocaleDateString('en-US')}`;
      }
      return label;
    },
    widgetTabChange() {
      this.isChartActive = !this.isChartActive;
      if (!this.widgetInfo && !this.isChartActive) {
        this.init();
      }
      if (!this.widgetChartData && this.isChartActive) {
        this.getWidgetChartData();
      }
    },
    handleChartChangeByField() {
      this.getWidgetChartData();
    },
    // async getPresetList() {
    //   const presets = await this.$store.dispatch('dashboard/getPresetList', {
    //     fieldName: this.title,
    //   });
    //   this.presetList = _.mapValues(presets, (o) => ([
    //     moment.unix(o.start_date),
    //     moment.unix(o.end_date),
    //   ]));
    // },
    setOldDateValues() {
      const startDate = moment.unix(this.oldSelectedDate.startDate);
      const endDate = moment.unix(this.oldSelectedDate.endDate);
      this.selected.startDate = startDate;
      this.selected.endDate = endDate;
    },
    setFirstLoadDate() {
      if (this.presetList) {
        const randomPreset = Object.values(this.presetList)[0];
        this.selected = this.presetList.yesterday
          ? { startDate: this.presetList.yesterday[0], endDate: this.presetList.yesterday[1] }
          : { startDate: randomPreset[0], endDate: randomPreset[1] };
        this.selectedChartDate = this.presetList.yesterday
          ? { startDate: this.presetList.yesterday[0], endDate: this.presetList.yesterday[1] }
          : { startDate: randomPreset[0], endDate: randomPreset[1] };
      }
    },
    async init(selected = this.selected) {
      if (!this.isLoading) {
        await this.$store.dispatch('dashboard/getWidgetInfo', {
          key: this.title,
          selected: {
            startDate: moment(selected.startDate).startOf('day').unix(),
            endDate: moment(selected.endDate).endOf('day').unix(),
          },
        });
      }
    },
    async getWidgetChartData(selected = this.selectedChartDate) {
      if (!this.isLoading) {
        await this.$store.dispatch('dashboard/getWidgetChartData', {
          key: this.title,
          selectedChartByField: this.selectedChartByField,
          selected: {
            startDate: moment(selected.startDate).startOf('day').unix(),
            endDate: moment(selected.endDate).endOf('day').unix(),
          },
        });
        if (this.chartListByField && !this.selectedChartByField) {
          this.chartListByField.forEach((option) => {
            if (this.widgetChartData[option.code]) {
              this.selectedChartByField = option.code;
            }
          });
        }
      }
    },
  },
};
</script>

<style lang="scss">
.widget-card-wrapper {
  border-radius: 8px;

  .dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    display: none;
    float: left;
    min-width: 10rem;
    padding: 0.5rem 0;
    margin: 0.125rem 0 0;
    font-size: 1rem;
    color: #212529;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 0.25rem;
  }

  .form-control {
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }

  .no-gutters {
    margin-right: 0;
    margin-left: 0;
  }

  .row {
    display: flex;
    flex-wrap: wrap;
  }

  .ant-card-head-title {
    overflow: visible;
  }

  .small-box, .bg-info {
    min-height: 275px;
  }

  .ant-card {
    min-height: 470px;
    margin: 12px 0;
    border-radius: 8px;
    border: none;
  }

  .card-heading {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .vue-daterange-picker {
      margin-left: auto;
    }

    .btn {
      color: black;
      width: 38px;
    }

    .refresh-icon {
      margin-left: 10px;
      margin-right: 4px;
    }

    .loadingBtn {
      /*animation: rotation .8s infinite;*/
      /*animation-timing-function: linear;*/
    }

    .error {
      color: red;
    }

    .card-title {
      text-align: left;
      font-size: 14px;
      border-right: 1px solid #E8E8E8;
      padding-inline-end: 8px;

      &::first-letter {
        text-transform: uppercase;
      }
    }
  }

  .widget-cell {
    margin-bottom: 1rem;
  }

  .inner {
    .card-row {
      .row-info {
        display: flex;
        justify-content: space-between;
        margin-bottom: 14px;

        .row-key {
          text-align: left;
          &::first-letter {
            text-transform: uppercase;
          }
        }
      }
    }
  }
}

.is-mobile {
  .inner {
    .title {
      font-size: 1.1rem;
    }
  }
}

.daterangepicker.hide-calendars .ranges li {
  width: 100%;
}

.is-mobile {
  .vue-daterange-picker {
    .form-control {
      >span {
        font-size: 12px;
      }
    }
    .dropdown-menu {
      /*left: 30%;*/
      .calendars-container {
        flex-wrap: wrap;
      }
    }
  }
}
</style>
