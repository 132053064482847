<template>
  <div ref="multiple-select" class="input-multiple-paginated-select">
    <a-form-item
      :validate-status="validatingStatus"
      :help="validateHelp"
      class="form-item-holder"
    >
      <template slot="label">
        <span class="form-item-label-text">{{ label }}</span>
      </template>
      <a-select
        v-model="selectedValue"
        :loading="loading"
        :show-search="true"
        mode="multiple"
        :size="size"
        placeholder="Please select"
        style="min-width: 200px"
        :disabled="disabled === 'disabled'"
        :validate-status="validatingStatus"
        :help="validateHelp"
        :allow-clear="true"
        :auto-clear-search-value="true"
        :filter-option="false"
        @change="handleChange"
        @popupScroll="onScrollValueGetter"
        @search="onInputSearch"
        @dropdownVisibleChange="dropdownVisibleChange"
      >
        <a-select-option v-for="(opt) in options"
                         :key="opt.code"
                         :value="opt.code"
                         :disabled="disabled && disabled.includes(opt.code)"
        >
          {{ opt.label }}
        </a-select-option>
      </a-select>
    </a-form-item>
  </div>
</template>
<script>
import path from 'path';
import socketError from '../../utils/socketError';

export default {
  // eslint-disable-next-line vue/require-prop-types
  props: ['type', 'label', 'fieldName', 'relation', 'oldValue', 'isValidationFirstError', 'validationError', 'serverSideDependence', 'disabled', 'requestMethod'],
  data() {
    return {
      path,
      options: [],
      size: 'large',
      loading: false,
      searchInputValue: '',
      page: 1,
      selectedValue: [],
      relationValues: [],
      optionLabels: [],
      validatingStatus: 'validating',
      validateHelp: '',
    };
  },
  watch: {
    async serverSideDependence(e, prevValue) {
      if (e && Object.keys(e)[0] && (Object.values(prevValue)[0] !== Object.values(e)[0])) {
        await this.setOptions(true);
      }
    },
    oldValue() {
      this.setOldValue();
    },
    isValidationFirstError(e) {
      if (e) {
        this.$refs['multiple-select'].scrollIntoView();
      }
    },
    validationError(e) {
      if (e) {
        this.validatingStatus = 'error';
        // eslint-disable-next-line prefer-destructuring
        this.validateHelp = e[0];
      } else {
        this.deleteValidationErrors();
      }
    },
  },
  async mounted() {
    await this.setOptions();
    this.setOldValue();
  },
  methods: {
    deleteValidationErrors() {
      this.validatingStatus = 'validating';
      this.validateHelp = '';
    },
    async dropdownVisibleChange(e) {
      if (!e) {
        this.searchInputValue = '';
        this.page = 1;
        await this.setOptions();
      }
    },
    handleChange() {
      // eslint-disable-next-line max-len
      // const multipleSelectValue = selectedIndexes.map((selectedIndex) => this.options[selectedIndex].code);
      // console.log('handleChange multipleSelectValue', multipleSelectValue);
      // console.log(this.selectedValue);
      this.$emit('input-change', { [this.fieldName]: this.selectedValue });
      this.deleteValidationErrors();
    },
    async onInputSearch(val) {
      this.loading = true;
      this.searchInputValue = val;
      const params = {
        page: 1,
        key: this.fieldName,
        s: this.searchInputValue,
        selected: JSON.stringify(this.selectedValue),
        exclude_selected: 1,
        type: this.type,
        ...this.serverSideDependence,
      };
      await this.getValues(params).then((result) => {
        this.page = 1;
        this.relationValues = result.data;
        this.options = [...this.relationValues];
        this.loading = false;
      }).catch(() => {
        this.loading = false;
      });
    },
    async onScrollValueGetter(event) {
      if (event.target.scrollHeight === event.target.offsetHeight + event.target.scrollTop
        && !this.loading) {
        this.loading = true;
        const params = {
          page: (this.page + 1) || 1,
          key: this.fieldName,
          s: this.searchInputValue,
          exclude_selected: 1,
          selected: JSON.stringify(this.oldValue),
          type: this.type,
          ...this.serverSideDependence,
        };
        await this.getValues(params).then((result) => {
          this.page = result.current_page;
          this.relationValues = result.data;
          this.options = [...this.options, ...this.relationValues];
          this.loading = false;
        }).catch(() => {
          this.loading = false;
        });
      }
    },
    async sendSelectedValues(selected) {
      const relationPath = path.join(this.$route.path, 'relation', this.relation);
      try {
        if (this.requestMethod === 'post') {
          const res = await axios.post(relationPath, {
            selected: JSON.stringify(selected),
            type: this.type,
            ...this.serverSideDependence,
          });
          return res.data;
        }
        const res = await axios.get(relationPath, {
          params: {
            selected: JSON.stringify(selected),
            type: this.type,
            ...this.serverSideDependence,
          },
        });
        return res.data;
      } catch (e) {
        socketError({}, e);
        return false;
      }
    },
    async getValues(params) {
      // console.log(`${this.$route.path}/relation/${this.relation}`);
      const relationPath = path.join(this.$route.path, 'relation', this.relation);
      try {
        if (this.requestMethod === 'post') {
          const res = await axios.post(relationPath, {
            ...params,
          });
          return res.data;
        }
        const res = await axios.get(relationPath, {
          params,
        });
        return res.data;
      } catch (e) {
        socketError({}, e);
        return false;
      }
    },
    setOldValue() {
      if (this.oldValue) {
        if (typeof this.oldValue !== 'object') {
          this.selectedValue = [this.oldValue];
        } else {
          this.selectedValue = [...this.oldValue];
        }
        this.$emit('input-change', { [this.fieldName]: this.oldValue });
      }
    },
    async setOptions(serverSideDependence = false) {
      this.loading = true;
      let val = [];
      if (typeof this.oldValue !== 'object') {
        val = this.oldValue && [this.oldValue];
      } else {
        val = [...this.oldValue];
      }
      if (val && val[0]) {
        await this.sendSelectedValues(val)
          .then((result) => {
            // this.relationValues = result.data;
            this.options = [...result.data];
            this.page = result.current_page;
            this.loading = false;
          });
      }
      this.relationValues = (await this.getValues({
        selected: JSON.stringify(this.oldValue),
        exclude_selected: 1,
        type: this.type,
        ...this.serverSideDependence,
      })).data;
      // eslint-disable-next-line max-len
      if ((this.options[0] && this.options[0].code) !== (this.relationValues[0] && this.relationValues[0].code)) {
        if (serverSideDependence) {
          this.options = [...this.relationValues];
        } else {
          this.options = [...this.options, ...this.relationValues];
        }
      }
      this.loading = false;
    },
  },
};
</script>

<style lang="scss">
.input-multiple-paginated-select {
  text-align: left;

  label {
    font-size: 14px!important;
    font-weight: 400!important;
    color: rgba(0, 0, 0, 0.85);
  }

  .ant-select-selection.ant-select-selection--multiple {
    max-height: 400px;
    overflow-y: scroll;

    /* width */
    &::-webkit-scrollbar {
      width: 7px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #888;
      border-radius: 3px;

      /* Handle on hover */
      &:hover {
        background: #555;
      }
    }

  }
}
</style>
