var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"exact-client-page"},[_c('a-spin',{attrs:{"spinning":_vm.isLoading}},[_c('div',{staticClass:"client-page-header"},[_c('ClientInfoCard',{attrs:{"data":_vm.exactClientInfo.clientInfo,"hide-edit-btn":false}}),_c('ClientDetailsCards',{attrs:{"data":_vm.exactClientInfo.clientDetails}})],1),_c('ClientPageMenu',{attrs:{"exact-user-menu-section":_vm.exactUserMenuSection}},[_c('div',{staticClass:"page-wrapper"},[(_vm.$route.params.menuItem==='main'|| _vm.$route.params.menuItem==='')?_c('div',{staticClass:"main-page-info"},[_c('ClientBalanceCard',{attrs:{"data":_vm.exactClientInfo.clientBalance}}),_vm._l((_vm.exactClientInfo.totalTransactions),function(card,index){return _c('WidgetChartCard',{key:index,attrs:{"title":card.title,"data":card.data}})})],2):_c('div',{ref:"pageInfo",staticClass:"page-info"},[(_vm.$route.params.menuItem==='correction'
                 || _vm.$route.params.menuItem==='client-notification')?_c('div',{staticClass:"correction-form-page"},[_c('FormPage',{attrs:{"form-data":_vm.exactClientPageInfo && _vm.exactClientPageInfo.fields,"old-form-values":_vm.exactClientPageInfo && _vm.exactClientPageInfo.result,"own-submit":true,"is-loading":_vm.isFormPageLoading},on:{"form-data-submit":_vm.formDataSubmit}})],1):_c('div',_vm._l((_vm.exactClientPageInfo),function(table,key){return _c('div',{key:key},[(table.filter)?_c('div',{staticClass:"filter-wrapper"},[_c('FilterForm',{attrs:{"filter-list":table.filter,"own-submit":true,"is-loading":_vm.isLoading,"old-form-values":_vm.exactClientSearchFormData},on:{"filter-form-search":_vm.onFilterFormSearch}})],1):_vm._e(),_c('div',[_c('AntdTable',{attrs:{"loading":_vm.isLoading,"columns":table.columns,"rows":table.results.data,"order-params":_vm.exactClientOrderParams,"total":table.results.total,"page-value":table.results.current_page,"page-count":table.results
                    && Math.ceil(table.results.total / table.results.per_page),"pagination":Math.ceil(table.results.total / table.results.per_page) > 1,"per-page":table.results.per_page},on:{"on-page-change":_vm.tablePageChange,"per-page-select-change":_vm.perPageSelectChange,"search-by-order":_vm.searchByOrder}},[_c('div',[(table.actions && table.actions[0])?_c('div',_vm._l((table.actions),function(action,index){return _c('span',{key:index},[(action.field === 'create')?_c('a-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.toggleCreateFormModal('create')}}},[_vm._v(" "+_vm._s(action.label)+" ")]):_vm._e()],1)}),0):_vm._e(),_c('div',[(_vm.downLoadToken)?_c('DownLoadFile',{attrs:{"search-params":{
                                      orderBy: _vm.orderParams.orderBy || 'ASC',
                                      column: _vm.orderParams.column || '',
                                      perPage: 5000,
                                      ..._vm.filterFormData,
                                      token: _vm.downLoadToken,
                                    }}}):_vm._e()],1)])])],1)])}),0)])])]),_c('EditFormPage',{attrs:{"visible":_vm.isClientEditFormModalOpen,"type":"edit","update-dispatch-action":"player/updatePlayerModuleById","get-edit-form-dispatch-action":"player/editPlayerModuleById","state-name":"player","unmount-action":"player/deleteEditFormJson","request-path":"/player/client","id-of-edit-modal":_vm.isClientEditFormModalOpen},on:{"toggle-modal":_vm.toggleClientEditFormModal}}),_c('EditFormPage',{attrs:{"visible":_vm.isEditFormModalOpen,"type":"edit","update-dispatch-action":"player/updatePlayerModuleById","get-edit-form-dispatch-action":"player/editPlayerModuleById","state-name":"player","unmount-action":"player/deleteEditFormJson","request-path":!_vm.$route.params.menuItem || _vm.$route.params.menuItem === 'main'
        ? '/player/client'
        : `/player/${_vm.$route.params.module}/${_vm.$route.params.id}/${_vm.$route.params.menuItem}`,"id-of-edit-modal":_vm.isEditFormModalOpen},on:{"toggle-modal":_vm.toggleEditFormModal}}),_c('EditFormPage',{attrs:{"type":"edit","visible":_vm.isEditBalanceFormModalOpen,"update-dispatch-action":"player/updatePlayerModuleById","get-edit-form-dispatch-action":"player/editPlayerModuleById","state-name":"player","unmount-action":"player/deleteEditFormJson","request-path":`/player/${_vm.$route.params.module}/${_vm.$route.params.id}/client-balance`,"id-of-edit-modal":_vm.isEditBalanceFormModalOpen},on:{"toggle-modal":_vm.toggleBonusBalanceEditFormModal}}),_c('CreateFormPage',{attrs:{"visible":_vm.isCreateFormModalOpen,"id-of-create-modal":_vm.isCreateFormModalOpen},on:{"toggle-modal":function($event){return _vm.toggleCreateFormModal('after-create')}}}),(_vm.isDeleteModalOpen)?_c('DeleteModal',{attrs:{"info":_vm.isDeleteModalOpen,"delete-vuex-action":"player/deleteById"},on:{"update-table-info":_vm.getPlayerInfo}}):_vm._e(),_c('AntdModal',{attrs:{"text":`Are You Sure You Want To Verify Client With ID: ${_vm.$route.params.id}`,"ok-text":"Verify","visible":_vm.isVerifyModalOpen},on:{"handle-save":_vm.verifyClient,"close-modal":() => (_vm.isVerifyModalOpen = false)}}),_c('RiskStatusModal',{attrs:{"is-loading":_vm.isLoading,"default-value":_vm.clientRiskStatus,"client-id":_vm.$route.params.id,"visible":_vm.isRiskStatusModalOpen},on:{"handle-accept":_vm.changeRiskStatus,"close-modal":() => (_vm.isRiskStatusModalOpen = false)}}),_c('AntdModal',{attrs:{"text":`Are You Sure You Want To Verify
      Client With ID: ${_vm.$route.params.id} Without Giving Bonus`,"ok-text":"Verify","visible":_vm.isWithoutBonusVerifyModalOpen},on:{"handle-save":_vm.verifyWithoutBonus,"close-modal":() => (_vm.isWithoutBonusVerifyModalOpen = false)}}),_c('AntdModal',{attrs:{"text":`Are You Sure You Want To Unverify
      Client With ID: ${_vm.$route.params.id}`,"ok-text":"Unverify","visible":_vm.isUnVerifyModalOpen},on:{"handle-save":_vm.unVerifyClient,"close-modal":() => (_vm.isUnVerifyModalOpen = false)}}),_c('IframeModal',{attrs:{"visible":Boolean(_vm.isIframeModalOpen),"req-url":_vm.isIframeModalOpen},on:{"toggle-modal":_vm.toggleIframeModal}}),(_vm.isApproveModalOpen)?_c('ApproveModal',{attrs:{"id":_vm.isApproveModalOpen}}):_vm._e(),(_vm.isRejectModalOpen)?_c('RejectModal',{attrs:{"modal-form-content":_vm.isRejectModalOpen}}):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }